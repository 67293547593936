import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function completeJobReducer(state = initialState.completingJob, action) {
  switch (action.type) {
    case types.COMPLETE_JOB_SUCCESS:
      return action.completingJob;

    case types.RELOAD_COMPLETE_JOB_SUCCESS:
      return action.completingJob;

    default:
      return state;
  }
}
