import { WebAuth } from "auth0-js";

const REDIRECT_ON_LOGIN = "redirect_on_login";

// eslint-disable-next-line
let _idToken = null;
let _accessToken = null;
let _scopes = null;
let _expiresAt = null;

export default class Auth {
  constructor(history) {
    this.history = history;

    this.userProfile = null;
    this.requestedScopes = "openid profile email";

    // Setup Auth0 configuration
    this.auth0 = new WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      responseType: "token id_token",
      scope: this.requestedScopes,
    });
  }

  // Function to login
  login = () => {
    // store the user location so we can redirect the user back to same place after login
    localStorage.setItem(REDIRECT_ON_LOGIN, JSON.stringify(this.history.location));

    // this will redirect user to Auth0 to login
    this.auth0.authorize();
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        // redirect user back to wherever they were at
        const redirectLocation =
          localStorage.getItem(REDIRECT_ON_LOGIN) === "undefined"
            ? "/"
            : JSON.parse(localStorage.getItem(REDIRECT_ON_LOGIN));

        this.history.push(redirectLocation);
      } else if (err) {
        this.history.push("/");
        //alert(`Error: ${err.error}. Check console for details`);
        console.log(err);
      }

      localStorage.removeItem(REDIRECT_ON_LOGIN);
    });
  };

  setSession = (authResult) => {
    // Set the time the access token will expire
    _expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    _scopes = authResult.scope || this.requestedScopes || "";

    _accessToken = authResult.accessToken;
    _idToken = authResult.idToken;

    // Set Token renewal so that we stayed logged in whilst we are in the app
    this.scheduleTokenRenewal();
  };

  isAuthenticated() {
    return new Date().getTime() < _expiresAt;
  }

  logout = () => {
    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: process.env.REACT_APP_APP_URL,
    });
  };

  getAccessToken = () => {
    if (!_accessToken) {
      throw new Error("No access token found");
    }
    return _accessToken;
  };

  getProfile = (cb) => {
    if (this.userProfile) return cb(this.userProfile);
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      if (profile) this.userProfile = profile;
      cb(profile, err);
    });
  };

  // Function to check whether user has all of the scopes in the array of scopes passed in
  userHasScope(scopes) {
    // Create an array of scopes in localStorage
    const grantedScopes = (_scopes || "").split(" ");

    // Iterate over all scopes passed in and checks whether it exists in localStorage
    return scopes.every((scope) => grantedScopes.includes(scope));
  }

  // Function to renew token us user is still active
  renewToken(cb) {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(`Error: ${err.error} - ${err.description}.`);
      } else {
        this.setSession(result);
      }
      if (cb) cb(err, result);
    });
  }

  scheduleTokenRenewal() {
    const delay = _expiresAt - Date.now();
    if (delay > 0) setTimeout(() => this.renewToken(), delay);
  }
}
