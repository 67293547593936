import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContext from "./AuthContext";

function PrivateRoute({ component: Component, scopes, ...rest }) {
  // This function could be enhanced to include checking of Roles as well

  // Added AuthContext.Consumer below to access the auth object from AuthContext
  return (
    <AuthContext.Consumer>
      {(auth) => (
        <Route
          {...rest}
          render={(props) => {
            // 1. Redirect to login if not logged in
            if (!auth.isAuthenticated()) return auth.login();

            // 2. Display message if user lacks required scopes
            if (scopes.length > 0 && !auth.userHasScope(scopes)) {
              return <h1>Unauthorised - You need the following scope(s) to view this page: {scopes.join(",")}.</h1>;
            }

            // 3. Render component
            return <Component auth={auth} {...props} />;
          }}
        />
      )}
    </AuthContext.Consumer>
  );
}

PrivateRoute.propTypes = {
  // Review line below, 'any' type should be 'func'
  component: PropTypes.any.isRequired,
  scopes: PropTypes.array,
};

PrivateRoute.defaultProps = {
  scopes: [],
};

export default PrivateRoute;
