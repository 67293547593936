import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NewJobs from "./NewJobs";
import MyJobs from "./MyJobs";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import * as jobActions from "../../redux/actions/jobActions";
import * as profileActions from "../../redux/actions/profileActions";
import { toast } from "react-toastify";
import { confirm } from "../common/ConfirmDialog";
import MyCompletedJobs from "./MyCompletedJob";
import CompleteJobPage from "./CompleteJobPage"; // eslint-disable-line import/no-named-as-default

const VIEW_NEW_JOBS = "new";
const VIEW_MY_JOBS = "myAssigned";
const VIEW_COMPLETED_JOBS = "viewCompleted";
const VIEW_JOB_COMPLETION = "viewJobCompletion";

export function JobsPage({
  jobs,
  profile,
  claimedJob,
  loadJobs,
  loadProfile,
  claimJob,
  myJobs,
  loadMyJobs,
  myCompletedJobs,
  loadMyCompletedJobs,
  completeJob,
  completingJob,
  auth,
  apiCallsInProgress,
}) {
  const [viewArea, setViewArea] = useState(VIEW_NEW_JOBS);

  useEffect(() => {
    if (profile && Object.keys(profile).length === 0) {
      loadProfile(auth).catch((error) => {
        console.log("error loading profile");
        throw error;
      });
    }

    if (jobs.length === 0) {
      loadJobs(auth).catch((error) => {
        console.log("error loading jobs: " + error);
      });
    }

    if (profile && Object.keys(profile).length > 0 && myJobs.length === 0) {
      loadMyJobs(auth).catch((error) => {
        console.log("error loading my jobs: " + error);
      });
    }

    if (profile && Object.keys(profile).length > 0 && myCompletedJobs.length === 0) {
      loadMyCompletedJobs(auth).catch((error) => {
        console.log("error loading completed jobs: " + error);
      });
    }
  }, []);

  useEffect(() => {
    loadJobs(auth).catch((error) => {
      console.log("error loading jobs: " + error);
    });

    if (profile && Object.keys(profile).length > 0) {
      loadMyJobs(auth).catch((error) => {
        console.log("error loading my jobs: " + error);
      });
    }

    if (profile && Object.keys(profile).length > 0) {
      loadMyCompletedJobs(auth).catch((error) => {
        console.log("error loading completed jobs: " + error);
      });
    }
  }, [claimedJob, completingJob]);

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      loadMyJobs(auth, profile).catch((error) => {
        console.log("error loading my jobs: " + error);
      });
    }

    if (profile && Object.keys(profile).length > 0 && myCompletedJobs.length === 0) {
      loadMyCompletedJobs(auth).catch((error) => {
        console.log("error loading completed jobs: " + error);
      });
    }
  }, [profile]);

  function clickHandler(event) {
    event.preventDefault();

    setViewArea(event.target.value);
  }

  // Function to handle 'Claim Job' click event - show confirmation
  async function claimJobHandler(event) {
    const claim = jobs.find((e) => e.orderNumber === event.target.value);

    if (
      await confirm(`Are you sure you wish to claim job number ${claim.orderNumber}?`, "Yes", "Cancel", {
        title: `Confirm Claim for ${profile.name.firstName} ${profile.name.lastName}`,
      })
    ) {
      // Implement code to make sure job is still available and then update with claimed engineer details
      claimJob(auth, claim, profile)
        .then(() => {
          toast.success(
            `Order ${claim.orderNumber} for ${claim.name.firstName} ${claim.name.lastName} has now been assigned to you`
          );
        })
        .catch(() => {
          toast.error(
            `Failed to claim ${claim.orderNumber} for ${claim.name.firstName} ${claim.name.lastName} - please contact us`
          );
        });
    }
  }

  // Function to handle complete job
  async function completeJobHandler(event) {
    const jobToComplete = myJobs.find((e) => e.orderNumber === event.target.value);
    completeJob(jobToComplete);
    setViewArea(VIEW_JOB_COMPLETION);
  }

  function renderView() {
    switch (viewArea) {
      case VIEW_COMPLETED_JOBS:
        return <MyCompletedJobs myCompletedJobs={myCompletedJobs} />;

      case VIEW_MY_JOBS:
        return <MyJobs myJobs={myJobs} completeJobHandler={completeJobHandler} />;

      case VIEW_JOB_COMPLETION:
        return <CompleteJobPage auth={auth} profile={profile} completedJob={completingJob} />;

      default:
        return <NewJobs jobsToList={jobs} claimJobHandler={claimJobHandler} />;
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>Jobs</h2>
          <p>View details of new jobs, claim jobs and update your claimed jobs</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <button className="btn btn-primary me-3 notification" value={VIEW_NEW_JOBS} onClick={clickHandler}>
            New Jobs
            <span className="badge">{jobs.length}</span>
          </button>
          <button className="btn btn-primary me-3 notification" value={VIEW_MY_JOBS} onClick={clickHandler}>
            My Jobs
            <span className="badge">{myJobs.length}</span>
          </button>
          <button className="btn btn-primary me-3 notification" value={VIEW_COMPLETED_JOBS} onClick={clickHandler}>
            Completed Jobs
            <span className="badge">{myCompletedJobs.length}</span>
          </button>
        </div>
      </div>
      {apiCallsInProgress > 0 ? <Spinner /> : renderView()}
    </div>
  );
}

JobsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
  loadJobs: PropTypes.func.isRequired,
  claimedJob: PropTypes.object.isRequired,
  claimJob: PropTypes.func.isRequired,
  myJobs: PropTypes.array.isRequired,
  myCompletedJobs: PropTypes.array.isRequired,
  loadMyCompletedJobs: PropTypes.func.isRequired,
  loadMyJobs: PropTypes.func.isRequired,
  apiCallsInProgress: PropTypes.number,
  completeJob: PropTypes.func,
  completingJob: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    jobs: state.jobs,
    claimedJob: state.claimedJob,
    myJobs: state.myJobs,
    myCompletedJobs: state.myCompletedJobs,
    apiCallsInProgress: state.apiCallsInProgress,
    completingJob: state.completingJob,
  };
}

const mapDispatchToProps = {
  loadJobs: jobActions.loadJobs,
  loadProfile: profileActions.loadProfile,
  claimJob: jobActions.claimJob,
  loadMyJobs: jobActions.loadMyJobs,
  loadMyCompletedJobs: jobActions.loadMyCompletedJobs,
  completeJob: jobActions.completeJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage);
