import React from "react";
import PropTypes from "prop-types";

function UpdateGasSafeCard({
  uploadCardFrontToClientFunc,
  uploadCardRearToClientFunc,
  inputCardExpiryFunc,
  formError,
}) {
  return (
    <div className="row">
      <div className="form-group mb-3">
        <label htmlFor="uploadGasSafeCardFront">Upload an image of the FRONT of your Gas Safe Card</label>
        <input
          type="file"
          className="form-control"
          id="uploadGasSafeCardFront"
          name="gasSafeCardFront"
          placeholder="upload front of Gas Safe Card"
          onChange={uploadCardFrontToClientFunc}
          accept="image/*, .pdf"
          required
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="uploadGasSafeCardRear">Upload an image of the REAR of your Gas Safe Card</label>
        <input
          type="file"
          className="form-control"
          id="uploadGasSafeCardRear"
          name="gasSafeCardRear"
          placeholder="upload rear of Gas Safe Card"
          onChange={uploadCardRearToClientFunc}
          accept="image/*, .pdf"
          required
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="inputGasSafeExpiryDate">Enter the date your Gas Safe Card expires</label>
        <input
          type="date"
          className="form-control"
          id="inputGasSafeExpiryDate"
          name="gasSafeExpiryDate"
          placeholder="enter expiry date"
          onChange={inputCardExpiryFunc}
          required
        />
      </div>
      {formError ? <p className="fw-bold text-danger">*Please fill in all details</p> : <></>}
    </div>
  );
}

UpdateGasSafeCard.propTypes = {
  uploadCardFrontToClientFunc: PropTypes.func.isRequired,
  uploadCardRearToClientFunc: PropTypes.func.isRequired,
  inputCardExpiryFunc: PropTypes.func.isRequired,
  formError: PropTypes.bool.isRequired,
};

export default UpdateGasSafeCard;
