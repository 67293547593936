import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Calendar, DateObject } from "react-multi-date-picker";
import { addAvailabilityArray, removeAvailabilityArray } from "../../api/engineerApi";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import * as profileActions from "../../redux/actions/profileActions";

// eslint-disable-next-line no-unused-vars
const Availability = ({ profile, auth, loadProfile }) => {
  const [formChanged, setFormChanged] = useState(false);
  const [datesAdded, setDatesAdded] = useState([]);
  const [datesRemoved, setDatesRemoved] = useState([]);

  const today = new Date();

  const dateFormatter = ({ date }) => {
    let props = {};
    let isWeekend = [0, 6].includes(date.weekDay.index);
    if (isWeekend) props.className = "highlight highlight-red";
    return props;
  };

  useEffect(async () => {
    if (profile && Object.keys(profile).length === 0) {
      await loadProfile(auth).catch((error) => {
        console.log("error loading profile");
        throw error;
      });
    }
  }, [profile]);

  useEffect(() => {
    if (formChanged) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }
  }, [formChanged]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const previousDates = () => {
    const profileDates = [];
    profile.availability.forEach((availableDate) => {
      const markDate = new DateObject(availableDate.dateAvailable);
      if (markDate > today) {
        profileDates.push(markDate);
      }
    });
    return profileDates;
  };

  const checkDateExistsInProfile = (date) => {
    let returnValue = false;
    const dateObj = new DateObject(date);
    profile.availability.forEach((profileDate) => {
      const checkDate = new DateObject(profileDate.dateAvailable);
      if (checkDate.toDate().toDateString() == dateObj.toDate().toDateString()) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const dateChangeHandler = async (dateFocused, dateClicked) => {
    setFormChanged(true);

    const dateClickedObj = new DateObject(dateClicked);
    const existsInProfile = checkDateExistsInProfile(dateClickedObj);

    if (dateFocused !== undefined && dateFocused.toDate().toDateString() == dateClicked.toDate().toDateString()) {
      // Date added (highlighted)
      if (existsInProfile) {
        // Date already in DB
        const tempDates = datesRemoved.filter((e) => {
          return e.toDate().toString() !== dateClickedObj.toDate().toString();
        });
        setDatesRemoved(tempDates);
      } else {
        // Date not already in DB
        setDatesAdded([...datesAdded, dateClickedObj]);
      }
    } else {
      // Date removed
      if (existsInProfile) {
        // Date in DB
        setDatesRemoved([...datesRemoved, dateClickedObj]);
      } else {
        // Date not already in DB
        const tempDates = datesAdded.filter((e) => {
          return e.toDate().toString() !== dateClickedObj.toDate().toString();
        });
        setDatesAdded(tempDates);
      }
    }
  };

  const [availableDates, setAvailableDates] = useState(previousDates());
  const calenderProps = {
    multiple: true,
    value: availableDates,
    onChange: setAvailableDates,
    numberOfMonths: 3,
    minDate: today,
    weekStartDayIndex: 1,
    mapDays: dateFormatter,
    onFocusedDateChange: dateChangeHandler,
  };

  useEffect(() => {
    setAvailableDates(previousDates());
  }, [profile]);

  const saveChanges = async () => {
    setFormChanged(false);

    toast.success("Saving", {
      autoClose: 2000,
      hideProgressBar: true,
      position: "top-right",
      bodyClassName: "toast-body",
      progressClassName: "toast-progress",
      className: "toast-message",
      closeButton: false,
      icon: false,
    });

    try {
      const datesToAdd = [];
      const datesToRemove = [];

      datesAdded.forEach(async (date) => {
        datesToAdd.push(date.toDate().toDateString());
      });
      datesRemoved.forEach(async (date) => {
        datesToRemove.push(date.toDate().toDateString());
      });

      if (datesToAdd.length > 0) await addAvailabilityArray(auth, datesToAdd);
      if (datesToRemove.length > 0) await removeAvailabilityArray(auth, datesToRemove);

      await loadProfile(auth);
    } catch (error) {
      toast.error("Error saving", {
        autoClose: 2000,
        hideProgressBar: true,
        position: "top-right",
        bodyClassName: "toast-body",
        progressClassName: "toast-progress",
        className: "toast-message",
        closeButton: false,
        icon: false,
      });
    }
  };

  const cancelChanges = async () => {
    setFormChanged(false);
    setDatesAdded([]);
    setDatesRemoved([]);
    setAvailableDates(previousDates());
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-12">
          <p className="lead fw-bold">Availability</p>
          <div id="calendarArea" className="">
            <Calendar {...calenderProps} className="mx-auto" />
          </div>
        </div>
      </div>
      {formChanged ? (
        <div className="border-1 border-dark border bg-light m-3">
          <div className="row">
            <div className="col-md-12 mt-3 text-center">
              <p className="lead">Do you want to save your changes?</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-0 mb-3 text-center">
              <button type="submit" className="btn btn-success me-3" onClick={saveChanges}>
                Save
              </button>
              <button type="cancel" className="btn btn-danger" onClick={cancelChanges}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

Availability.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

const mapDispatchToProps = {
  loadProfile: profileActions.loadProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
