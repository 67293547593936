import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";

const Menu = function (props) {
  const activeStyle = { color: "#F15B2A" };
  const { isAuthenticated, login, logout } = props.auth;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <nav className="navbar navbar-light">
            <NavLink className="nav-item me-3" to="/" exact activeStyle={activeStyle}>
              Home
            </NavLink>
            <NavLink className="nav-item me-3" to="/jobs" activeStyle={activeStyle}>
              Jobs
            </NavLink>
            <NavLink className="nav-item me-3" to="/engineer" activeStyle={activeStyle}>
              My Details
            </NavLink>
            <button className="nav-item btn btn-danger ms-auto " onClick={isAuthenticated() ? logout : login}>
              {isAuthenticated() ? (
                <>
                  <FontAwesomeIcon icon={faSignOut} className="me-2" />
                  Sign Out
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSignIn} className="me-2" />
                  Sign In
                </>
              )}
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  auth: PropTypes.object,
};

export default Menu;
