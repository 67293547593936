export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_ENGINEER_SUCCESS = "LOAD_ENGINEER_SUCCESS";
export const UPDATE_ENGINEER_SUCCESS = "UPDATE_ENGINEER_SUCCESS";
export const LOAD_JOBS_SUCCESS = "LOAD_JOBS_SUCCESS";
export const CLAIM_JOB_SUCCESS = "CLAIM_JOB_SUCCESS";
export const LOAD_MY_JOBS_SUCCESS = "LOAD_MY_JOBS_SUCCESS";
export const LOAD_MY_COMPLETED_JOBS_SUCCESS = "LOAD_MY_COMPLETED_JOBS_SUCCESS";
export const COMPLETE_JOB_SUCCESS = "COMPLETE_JOB_SUCCESS";
export const RELOAD_COMPLETE_JOB_SUCCESS = "RELOAD_COMPLETE_JOB_SUCCESS";
