import React from "react";
import PropTypes from "prop-types";

const EngineerPersonalDetail = ({ userProfile, switchEditMode }) => {
  const profileImageExists = userProfile.engineerImageUrl ? true : false;
  const strapi_url = process.env.REACT_APP_IMAGE_URL;

  return (
    <div>
      <div className="row">
        <p className="lead fw-bold">Personal Details</p>
        <div className="col-xs-12 col-md-6 mb-3">
          <p className="lead mb-0">Personal</p>
          <div className="border p-2 shadow">
            <div className="text-center">
              {profileImageExists ? (
                <img
                  className="mb-3"
                  src={strapi_url + userProfile.engineerImageUrl}
                  style={{ width: 300, height: 300 }}
                />
              ) : (
                <p>No profile image provided</p>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="name"
                value={userProfile.title + " " + userProfile.firstName + " " + userProfile.lastName}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <input className="form-control" type="email" readOnly={true} id="email" value={userProfile.email} />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="phone">Mobile Number</label>
              <input className="form-control" type="text" readOnly={true} id="phone" value={userProfile.mobileNumber} />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="inputGasSafeNumber">Gas Safe License Number</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="inputGasSafeNumber"
                value={userProfile.gasSafeLicenseNumber}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6 mb-3">
          <p className="lead mb-0">Company</p>
          <div className="border p-2 shadow">
            <div className="form-group mb-3">
              <label htmlFor="companyName">Name</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="companyName"
                value={userProfile.companyName}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="address">Address</label>
              <textarea
                className="form-control"
                readOnly={true}
                id="address"
                rows="6"
                value={`${userProfile.address1}${"\n"}${userProfile.address2}${"\n"}${userProfile.address3}${"\n"}${
                  userProfile.city
                }${"\n"}${userProfile.county}${"\n"}${userProfile.postcode}`}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="companyGasSafeNumber">Gas Safe Number</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="companyGasSafeNumber"
                value={userProfile.companyGasSafeNumber}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="companyType">Type</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="companyType"
                value={userProfile.companyType == "limitedCompany" ? "Limited Compnay" : "Sole Trader"}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="companyNumber">Limited Company Number</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="companyNumber"
                value={!userProfile.companyNumber ? "" : userProfile.companyNumber}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="vatRegistered">VAT Registered</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="vatRegistered"
                value={userProfile.vatRegistered == false || userProfile.vatRegistered == "false" ? "No" : "Yes"}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="vatNumber">VAT Number</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="vatNumber"
                value={!userProfile.vatNumber ? "" : userProfile.vatNumber}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center mt-3">
          <button type="button" className="btn btn-success" onClick={switchEditMode}>
            Update Personal Details
          </button>
        </div>
      </div>
    </div>
  );
};

EngineerPersonalDetail.propTypes = {
  userProfile: PropTypes.object.isRequired,
  switchEditMode: PropTypes.func.isRequired,
};

export default EngineerPersonalDetail;
