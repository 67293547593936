import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as jobActions from "../../redux/actions/jobActions";
import { CompleteJobMenu } from "./CompleteJobMenu";
import { ConfirmJobCompletionDetails } from "./ConfirmJobCompletionDetails";
import responseTypes from "../../utils/responseTypes";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { confirm } from "../common/ConfirmDialog";
import {
  startCompletion,
  submitSatisfaction,
  submitSerialNumbers,
  submitBenchmark,
  submitGasSignOff,
  submitElectricalSignOff,
  uploadInstallationImage,
  removeInstallationImage,
  submitAllInstallationImages,
  submitJobForQA,
} from "../../api/orderApi";

export function CompleteJobPage({ auth, completingJob, reloadCompletingJob }) {
  const [benchmarkImage, setBenchmarkImage] = useState(null);
  const [cp1Image, setCP1Image] = useState(null);
  const [installationImage, setInstallationImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {}, [completingJob]);

  const confirmDetailsHandler = async () => {
    await startCompletion(auth, completingJob);
    await reloadCompletingJob(auth, completingJob);
  };

  const submitSatisfactionHandler = async (signatureBase64) => {
    try {
      await submitSatisfaction(auth, completingJob, signatureBase64);
      await reloadCompletingJob(auth, completingJob);
      toast.success(`Successfully submitted customer satisfaction`);
    } catch (error) {
      toast.error(`Failed to submit customer satisfaction - please contact us if this error continues`);
    }
  };

  const productSerialsSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      // Handle the serial numbers
      const serialNumbers = {
        boilerSerialNumber: event.target.form.boilerSerial.value,
        filterSerialNumber: event.target.form.filterSerial.value,
      };

      if (serialNumbers.boilerSerialNumber === "" || serialNumbers.filterSerialNumber === "") {
        setError("productSerials", "incomplete", "Please provide all serial numbers before submitting");
      } else {
        await submitSerialNumbers(auth, completingJob, serialNumbers);
        await reloadCompletingJob(auth, completingJob);
        toast.success(`Successfully submitted product serial numbers`);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Failed to submit product serial numbers - please contact us if this error containues`);
    }
  };

  const benchmarkSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      if (benchmarkImage === null) {
        setError("benchmark", "noImage", "Please provide benchmark before submitting");
      } else {
        // Handle the benchmark image
        await submitBenchmark(auth, completingJob, benchmarkImage);
        await reloadCompletingJob(auth, completingJob);
        toast.success(`Successfully submitted benchmark`);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Failed to submit benchmar - please contact us if this error continues`);
    }
  };

  const uploadBenchmarkToClient = (event) => {
    // Handle the benchmark image being selected
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setBenchmarkImage(event.target.files[0]);
    }
  };

  const gasSignOffHandler = async (event) => {
    event.preventDefault();
    // Handle the gas sign off
    const signOffData = {
      gasVisualInspectionOk: event.target.form.gasVisualInspection.value,
      gasTightnessTestOk: event.target.form.gasTightnessTest.value,
      gasEmergencyControlAccessible: event.target.form.gasEmergencyControlsAccess.value,
      gasEquipotentialBondingOk: event.target.form.gasEquipotential.value,
      gasPipeworkWarningIssued: event.target.form.gasPipeworkWarning.value,
      gasAdeyProsenseOk: event.target.form.adeyProsenseInstalled.value,
      gasEngineerNotes: event.target.form.gasEngineerNotes.value,
    };

    try {
      const keys = Object.keys(signOffData);
      let inComplete = false;

      keys.forEach((key) => {
        if (key != "gasEngineerNotes" && signOffData[key] === "") {
          inComplete = true;
        }
      });

      if (inComplete || cp1Image === null) {
        setError(
          "gasSignOff",
          "inComplete",
          "Please answer all questions and provide an image of CP1 certificate before submitting"
        );
      } else {
        await submitGasSignOff(auth, completingJob, cp1Image, signOffData);
        await reloadCompletingJob(auth, completingJob);
        toast.success("Successfully submitted gas sign off");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit gas sign off - please contact us if this error continues");
    }
  };

  const uploadCP1ToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setCP1Image(event.target.files[0]);
    }
  };

  const electricalSignOffHandler = async (event) => {
    event.preventDefault();

    // Handle electrical sign off
    const signOffData = {
      electricBoilerConnected: event.target.form.elecBoilerConnected.value,
      electricBondedAndClamped: event.target.form.gasBonded.value,
      electricClippedAndTrunked: event.target.form.cablesClipped.value,
      electricSleevedAndClear: event.target.form.elecSleeved.value,
      electricDisconnection: event.target.form.elecDisconnection.value,
      electricDirectEarthToIsolation: event.target.form.boilerEarth.value,
      electricHeatingControlsOk: event.target.form.heatingControls.value,
      electricCustomerShownHeatingControls: event.target.form.heatingControlsDemo.value,
    };

    try {
      const keys = Object.keys(signOffData);
      let inComplete = false;

      keys.forEach((key) => {
        if (signOffData[key] === "") {
          inComplete = true;
        }
      });

      if (inComplete) {
        setError("electricalSignOff", "inComplete", "Please answer all questions before submitting");
      } else {
        await submitElectricalSignOff(auth, completingJob, signOffData);
        await reloadCompletingJob(auth, completingJob);
        toast.success("Successfully submmitted electrical sign off");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to submit electrical sign off - please contact us if this error continues");
    }
  };

  const installImagesSignOffHandler = async (event) => {
    event.preventDefault();

    try {
      // Check for the minimum images before allowing submit - add mandatory images to object below
      const requiredImages = [
        {
          name: "Boiler (Front)",
          exists: false,
        },
        {
          name: "Boiler (Underneath)",
          exists: false,
        },
        {
          name: "Gas Meter",
          exists: false,
        },
      ];

      completingJob.completionDetail.completionImages.forEach((image) => {
        requiredImages.forEach((requiredImage) => {
          if (requiredImage.name === image.imageTitle) {
            requiredImage.exists = true;
          }
        });
      });

      let allMandatoryImagesExists = true;
      requiredImages.forEach((mandatoryImage) => {
        if (mandatoryImage.exists === false) {
          allMandatoryImagesExists = false;
        }
      });

      if (!allMandatoryImagesExists) {
        let errorMsg = "Please upload all mandatory images - ";
        requiredImages.forEach((mandatoryImage) => {
          if (!mandatoryImage.exists) {
            errorMsg = errorMsg.concat(mandatoryImage.name, ", ");
          }
        });

        setError("installationImages", "missingMandatory", errorMsg);
      } else {
        // Handle installation images sign off
        await submitAllInstallationImages(auth, completingJob);
        await reloadCompletingJob(auth, completingJob);
        toast.success("Successfully submitted all images");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit images - please contact us if this error continues");
    }
  };

  const uploadInstallationImageToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setInstallationImage(event.target.files[0]);
    }
  };

  const addInstallationImageToJob = async (event) => {
    event.preventDefault();

    try {
      if (!installationImage || event.target.form.imageType.value === "") {
        setError("installationImages", "incomplete", "Please choose an image to upload and select the image type");
      } else {
        // Handler upload of installation image to job
        await uploadInstallationImage(auth, completingJob, installationImage, event.target.form.imageType.value);
        await reloadCompletingJob(auth, completingJob);
        toast.success("Image successfully uploaded");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to upload image - please contact us if this error continues");
    }
  };

  const removeInstallationFromJob = async (event) => {
    event.preventDefault();

    // Remove uploaded image
    try {
      await removeInstallationImage(auth, completingJob, event.target.value);
      await reloadCompletingJob(auth, completingJob);
      toast.success("Image successfully removed");
    } catch (error) {
      console.log(error);
      toast.error("Failed to remove image - please contact us if this error continues");
    }
  };

  const submitJobForQAHandler = async (event) => {
    event.preventDefault();

    const comDetails = completingJob.completionDetail;

    if (
      !comDetails.customerSatisfied ||
      !comDetails.benchmarkComplete ||
      !comDetails.productSerialsComplete ||
      !comDetails.gasSignOffComplete ||
      !comDetails.electricalSignOffComplete ||
      !comDetails.installationImagesComplete
    ) {
      setError(
        "main",
        "incompleteForm",
        "Please complete all sections of Job Completion form before submitting for QA"
      );
    } else {
      if (
        await confirm(`Are you sure you wish to submit this job to be QA Checked`, "Yes", "Cancel", {
          title: `Submit job for QA`,
        })
      ) {
        try {
          await submitJobForQA(auth, completingJob);
          await reloadCompletingJob(auth, completingJob);
          toast.success(`Job successfully submitted for QA`);
        } catch (error) {
          toast.error(`Failed to submit job for QA - Please contact us if this error continues`);
        }
      }
    }
  };

  const setError = (section, error, message) => {
    let errorExists = false;

    errorMessage.forEach((existingError) => {
      if (existingError.section === section && existingError.error === error) {
        errorExists = true;
      }
    });

    if (!errorExists) {
      const errorMsg = {
        id: errorMessage.length + 1,
        section: section,
        error: error,
        message: message,
      };

      const newErrorArray = JSON.parse(JSON.stringify(errorMessage));
      newErrorArray.push(errorMsg);

      setErrorMessage(newErrorArray);
    }
  };

  return (
    <div className="mt-3">
      <p className="lead border-bottom">
        Completing Job: <span className="fw-bold">{completingJob.orderNumber}</span>
      </p>
      {!completingJob.completionDetail.detailsConfirmed ? (
        <ConfirmJobCompletionDetails jobToComplete={completingJob} confirmHandler={confirmDetailsHandler} />
      ) : (
        <>
          <CompleteJobMenu
            jobToComplete={completingJob}
            submitSatisfactionHandler={submitSatisfactionHandler}
            productSerialsHandler={productSerialsSubmitHandler}
            benchmarkSubmitHandler={benchmarkSubmitHandler}
            uploadBenchmarkToClientFunc={uploadBenchmarkToClient}
            gasSignOffHandler={gasSignOffHandler}
            uploadCP1ToClientFunc={uploadCP1ToClient}
            electricalSignOffHandler={electricalSignOffHandler}
            installationImageSignOffHandler={installImagesSignOffHandler}
            uploadInstallationImageToClientFunc={uploadInstallationImageToClient}
            addInstallationImageToJob={addInstallationImageToJob}
            removeInstallationFromJob={removeInstallationFromJob}
            errorMessage={errorMessage}
            setError={setError}
          />
          <div className="my-3 text-end">
            <p className="text-muted mb-0">Please ensure all information is accurate and matches images supplied</p>
            <p className="text-muted">Any contradictions may delay payment</p>
            <button
              className="btn btn-success"
              onClick={submitJobForQAHandler}
              disabled={completingJob.orderStatus === responseTypes().STATUS_QA_PENDING}
            >
              Submit job for QA
            </button>
            {errorMessage.map((errorMsg) => {
              if (errorMsg.section === "main") {
                return (
                  <div key={errorMsg.id} className="text-center mt-3">
                    <p className="text-danger fw-bold">*{errorMsg.message}</p>
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
    </div>
  );
}

CompleteJobPage.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  completingJob: PropTypes.object.isRequired,
  reloadCompletingJob: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    myJobs: state.myJobs,
    completingJob: state.completingJob,
  };
}

const mapDispatchToProps = {
  completeJob: jobActions.completeJob,
  reloadCompletingJob: jobActions.reloadCompletingJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteJobPage);
