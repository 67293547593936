import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import profileReducer from "./profileReducer";
import jobReducer from "./jobReducer";
import claimedJobReducer from "./claimedJobReducer";
import myJobsReducer from "./myJobsReducer";
import myCompletedJobsReducer from "./myCompletedJobsReducer";
import completeJobReducer from "./completeJobReducer";

const rootReducer = combineReducers({
  apiCallsInProgress: apiCallsInProgress,
  profile: profileReducer,
  jobs: jobReducer,
  claimedJob: claimedJobReducer,
  myJobs: myJobsReducer,
  myCompletedJobs: myCompletedJobsReducer,
  completingJob: completeJobReducer,
});

export default rootReducer;
