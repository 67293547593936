import React from "react";
import PropTypes from "prop-types";
import { getDocumentDisplayName } from "../../api/engineerApi";

function UpdateStandardDocument({
  documentName,
  uploadDocToClientFunc,
  handleDocExpiryFunc,
  formError,
  hideExpiry,
  issuedDate = false,
}) {
  return (
    <div className="row">
      <div className="form-group mb-3">
        <label htmlFor="uploadGasSafeCardRear">Upload an image of your {getDocumentDisplayName(documentName)}</label>
        <input
          type="file"
          className="form-control"
          id="uploadDocument"
          name="uploadDocument"
          placeholder={`upload your ${getDocumentDisplayName(documentName)}`}
          onChange={uploadDocToClientFunc}
          accept="image/*, .pdf"
          required
        />
      </div>
      {hideExpiry ? (
        ""
      ) : (
        <div className="form-group mb-3">
          <label htmlFor="inputGasSafeExpiryDate">
            {issuedDate === false
              ? `Enter the date your ${getDocumentDisplayName(documentName)} expires`
              : `Enter the date your ${getDocumentDisplayName(documentName)} was issued`}
          </label>
          <input
            type="date"
            className="form-control"
            id="inputDocumentExpiryDate"
            name="documentExpiryDate"
            placeholder="enter expiry date"
            onChange={handleDocExpiryFunc}
            required
          />
        </div>
      )}
      {formError ? <p className="fw-bold text-danger">*Please fill in all details</p> : <></>}
    </div>
  );
}

UpdateStandardDocument.propTypes = {
  documentName: PropTypes.string.isRequired,
  uploadDocToClientFunc: PropTypes.func.isRequired,
  handleDocExpiryFunc: PropTypes.func,
  formError: PropTypes.bool.isRequired,
  hideExpiry: PropTypes.bool,
  issuedDate: PropTypes.bool,
};

export default UpdateStandardDocument;
