import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as ordersApi from "../../api/orderApi";

export function loadJobsSuccess(jobs) {
  return { type: types.LOAD_JOBS_SUCCESS, jobs: jobs };
}

export function claimJobSuccess(job) {
  return { type: types.CLAIM_JOB_SUCCESS, job: job };
}

export function loadMyJobsSuccess(myJobs) {
  return { type: types.LOAD_MY_JOBS_SUCCESS, myJobs: myJobs };
}

export function loadMyCompletedJobsSuccess(myCompletedJobs) {
  return { type: types.LOAD_MY_COMPLETED_JOBS_SUCCESS, myCompletedJobs: myCompletedJobs };
}

export function completingJobSuccess(completingJob) {
  return { type: types.COMPLETE_JOB_SUCCESS, completingJob: completingJob };
}

export function reloadCompletingJobSuccess(completingJob) {
  return { type: types.RELOAD_COMPLETE_JOB_SUCCESS, completingJob: completingJob };
}

export function loadJobs(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());

    return ordersApi
      .getNewOrders(auth)
      .then((jobsList) => {
        dispatch(loadJobsSuccess(jobsList));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        console.log(error);
      });
  };
}

export function loadMyJobs(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());

    return ordersApi
      .getMyOrders(auth)
      .then((jobsList) => {
        dispatch(loadMyJobsSuccess(jobsList));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

export function claimJob(auth, job, engineer) {
  return function (dispatch) {
    dispatch(beginApiCall());

    return ordersApi
      .claimOrder(auth, job, engineer)
      .then((claimedJob) => {
        dispatch(claimJobSuccess(claimedJob));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

export function completeJob(job) {
  return function (dispatch) {
    return dispatch(completingJobSuccess(job));
  };
}

export function loadMyCompletedJobs(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());

    return ordersApi
      .getMyCompletedOrders(auth)
      .then((jobsList) => {
        dispatch(loadMyCompletedJobsSuccess(jobsList));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

export function reloadCompletingJob(auth, job) {
  return function (dispatch) {
    dispatch(beginApiCall());

    return ordersApi
      .getOrderWithCompletionDetails(auth, job.orderNumber)
      .then((job) => {
        dispatch(reloadCompletingJobSuccess(job));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}
