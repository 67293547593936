import { handleResponse, handleError } from "./apiUtils";
import qs from "qs";

const baseUrl = process.env.REACT_APP_API_URL.concat("/engineer");

export function getEngineerById(auth, profile) {
  const query = qs.stringify({ populate: "*" });

  return fetch(baseUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify(profile),
    query: query,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAvailableAreas(auth) {
  return fetch(baseUrl + "/all-coverage-areas", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateCoveredAreas(auth, coveredAreas) {
  return fetch(baseUrl + "/coverage-area", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ coveredAreas }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function addAvailability(auth, newDate) {
  return fetch(baseUrl + "/availability", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ newDate }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function removeAvailability(auth, removeDate) {
  return fetch(baseUrl + "/availability-remove", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ removeDate }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updatePersonalDetails(auth, profileUpdates) {
  return fetch(baseUrl + "/update-profile", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ profileUpdates }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateGasSafeCard(auth, cardFront, cardRear, cardExpiry) {
  const sendBody = new FormData();
  sendBody.append("gasCardFront", cardFront);
  sendBody.append("gasCardRear", cardRear);
  sendBody.append("gasCardExpiry", cardExpiry);

  return fetch(baseUrl + "/documents/gas-safe-card", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: sendBody,
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function updateStandardDocument(auth, documentFile, documentExpiry, documentType) {
  const sendBody = new FormData();
  sendBody.append("documentFile", documentFile);
  sendBody.append("documentExpiry", documentExpiry);
  sendBody.append("documentType", documentType);

  return fetch(baseUrl + "/documents/document-update-standard", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: sendBody,
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export function getExpiryFieldName(documentType) {
  switch (documentType) {
    case GAS_SAFE_CARD_FRONT || GAS_SAFE_CARD_REAR:
      return "gasSafeCardExpiry";

    case CCN1:
      return "CCN1DocExpiry";

    case INSURANCE:
      return "insuranceDocExpiry";

    case FLUE_GAS_ANALYSER_CERTIFICATE:
      return "flueGasAnalyserCertificateExpiry";

    case DBS_CERTIFICATE:
      return "dbsCertificateExpiry";

    default:
      return "";
  }
}

export function getDocumentFieldName(documentType) {
  switch (documentType) {
    case GAS_SAFE_CARD_FRONT:
      return "gasSafeCardFront";

    case GAS_SAFE_CARD_REAR:
      return "gasSafeCardRear";

    case CCN1:
      return "CCN1Doc";

    case INSURANCE:
      return "insuranceDoc";

    case FLUE_GAS_ANALYSER_CERTIFICATE:
      return "flueGasAnalyserCertificate";

    case DBS_CERTIFICATE:
      return "dbsCertificate";

    case PROFILE_IMAGE:
      return "engineerImage";

    default:
      return "";
  }
}

export const GAS_SAFE_CARD_FRONT = "GasSafeCardFront";
export const GAS_SAFE_CARD_REAR = "GasSafeCardRear";
export const CCN1 = "CCN1";
export const INSURANCE = "Insurance";
export const FLUE_GAS_ANALYSER_CERTIFICATE = "FlueGasAnalyserCertificate";
export const DBS_CERTIFICATE = "DbsCertificate";
export const PROFILE_IMAGE = "EngineerImage";
