import React from "react";
import PropTypes from "prop-types";

function CustomerPersonalDetail({ job }) {
  return (
    <div className="col-md-3">
      <div className="form-group mb-3">
        <label htmlFor="address">Address</label>
        <textarea
          className="form-control"
          readOnly={true}
          id="address"
          rows="6"
          value={`${job.address.address1}${"\n"}${job.address.address2}${job.address.address2.length > 0 ? "\n" : ""}${
            job.address.address3
          }${job.address.address3.length > 0 ? "\n" : ""}${job.address.city}${"\n"}${job.address.county}${"\n"}${
            job.address.postcode
          }`}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="email">Email</label>
        <input className="form-control" type="email" readOnly={true} id="email" value={job.email} />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="phone">Mobile Number</label>
        <input className="form-control" type="text" readOnly={true} id="phone" value={job.mobileNumber} />
      </div>
    </div>
  );
}

CustomerPersonalDetail.propTypes = {
  job: PropTypes.object.isRequired,
};

export default CustomerPersonalDetail;
