import React from "react";
import PropTypes from "prop-types";

function ViewGasSafeCard({ userProfile }) {
  const cardFrontExists = userProfile.gasSafeCardFront.data ? true : false;
  const cardRearExists = userProfile.gasSafeCardRear.data ? true : false;
  const strapi_url = process.env.REACT_APP_IMAGE_URL;

  return (
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <p className="text-muted mb-0">Front of card</p>
        {cardFrontExists ? (
          <img
            className="mb-3"
            src={strapi_url + userProfile.gasSafeCardFront.data.attributes.url}
            style={{ width: 300, height: undefined }}
          />
        ) : (
          <p>No document uploaded</p>
        )}
      </div>
      <div className="col-xs-12 col-md-6">
        <p className="text-muted mb-0">Rear of card</p>
        {cardRearExists ? (
          <img
            className="mb-3"
            src={strapi_url + userProfile.gasSafeCardRear.data.attributes.url}
            style={{ width: 300, height: undefined }}
          />
        ) : (
          <p>No document uploaded</p>
        )}
      </div>
    </div>
  );
}

ViewGasSafeCard.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

export default ViewGasSafeCard;
