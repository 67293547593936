import React from "react";
import PropTypes from "prop-types";

export function ConfirmJobCompletionDetails({ jobToComplete, confirmHandler }) {
  return (
    <div className="row">
      <p className="lead">Complete this job?</p>
      <div className="col-xs-12 col-md-6">
        <p className="fw-bold">Customer Details</p>
        <div className="form-group mb-3">
          <label htmlFor="name">Customer Name</label>
          <input
            className="form-control"
            type="text"
            readOnly={true}
            id="name"
            value={`${jobToComplete.name.firstName} ${jobToComplete.name.lastName}`}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="address">Installation Address</label>
          <textarea
            className="form-control"
            readOnly={true}
            id="address"
            rows="6"
            value={`${jobToComplete.address.address1}${"\n"}${jobToComplete.address.address2}${
              jobToComplete.address.address2.length > 0 ? "\n" : ""
            }${jobToComplete.address.address3}${jobToComplete.address.address3.length > 0 ? "\n" : ""}${
              jobToComplete.address.city
            }${"\n"}${jobToComplete.address.county}${"\n"}${jobToComplete.address.postcode}`}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="phone">Customer Contact Number</label>
          <input className="form-control" type="text" readOnly={true} id="phone" value={jobToComplete.mobileNumber} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">Customer Email</label>
          <input className="form-control" type="text" readOnly={true} id="email" value={jobToComplete.email} />
        </div>
      </div>
      <div className="col-xs-12 col-md-6">
        <p className="fw-bold">Boiler Details</p>
        <div className="form-group mb-3">
          <label htmlFor="boilerMake">Boiler Make</label>
          <input
            className="form-control"
            type="text"
            readOnly={true}
            id="boilerMake"
            value={jobToComplete.boiler.data.attributes.make}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="boilerModel">Boiler Model</label>
          <input
            className="form-control"
            type="text"
            readOnly={true}
            id="boilerModel"
            value={jobToComplete.boiler.data.attributes.model}
          />
        </div>
      </div>
      <div className="text-end">
        <p className="me-3 fw-bold">Are you sure you wish to complete this job?</p>
        <button className="btn btn-success me-3" onClick={confirmHandler}>
          Yes and Continue
        </button>
      </div>
    </div>
  );
}

ConfirmJobCompletionDetails.propTypes = {
  jobToComplete: PropTypes.object.isRequired,
  confirmHandler: PropTypes.func.isRequired,
};
