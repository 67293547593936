import React from "react";
import PropTypes from "prop-types";
import { getDocumentFieldName } from "../../api/engineerApi";

function ViewStandardDocument({ userProfile, documentType, title = "" }) {
  const documentFieldName = getDocumentFieldName(documentType);
  const strapi_url = process.env.REACT_APP_IMAGE_URL;
  const documentExists = userProfile[documentFieldName].data ? true : false;

  return (
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <p className="text-muted mb-0">{title}</p>
        {documentExists ? (
          <img
            className="mb-3"
            src={strapi_url + userProfile[documentFieldName].data.attributes.url}
            style={{ width: 300, height: undefined }}
          />
        ) : (
          <p>No document uploaded</p>
        )}
      </div>
    </div>
  );
}

ViewStandardDocument.propTypes = {
  userProfile: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ViewStandardDocument;
