import React from "react";
import PropTypes from "prop-types";
import responseTypes from "../../utils/responseTypes";
import CustomerPersonalDetail from "./CustomerPersonalDetail";

const JobDetails = ({ job, options }) => {
  const renderJobInfoText = () => {
    let infoText = "";
    let combiSwapText = "";

    if (job.jobDetail.convertToCombi === true) {
      combiSwapText = "Yes";
    } else {
      if (job.boiler.data.attributes.type != responseTypes().BOILER_COMBI) {
        combiSwapText = "No";
      } else {
        combiSwapText = "Replacing a combi";
      }
    }

    infoText += `Current Boiler: ${job.jobDetail.currentBoilerType} \n`;
    infoText += `Convert to Combi: ${combiSwapText} \n`;
    infoText += `Boiler move required: ${job.jobDetail.boilerMove === true ? "Yes" : "No"} \n`;

    if (job.jobDetail.boilerMove === true) {
      infoText += `Current boiler location: ${job.jobDetail.currentBoilerLocation} \n`;
      infoText += `New boiler location: ${job.jobDetail.newBoilerLocation} \n`;
    }

    if (job.jobDetail.currentFlueExit.length > 0) {
      infoText += `Flue move required: No \n`;
      infoText += `Flue exit: ${job.jobDetail.currentFlueExit} \n`;
    } else {
      infoText += `Flue move required: Yes \n`;
      infoText += `New flue exit: ${job.jobDetail.newFlueExit} \n`;
    }

    if (job.jobDetail.roofType.length > 0) {
      infoText += `Roof type: ${job.jobDetail.roofType} \n`;
    }

    if (job.jobDetail.roofFlueExitPosition.length > 0) {
      infoText += `Roof flue exit position: ${job.jobDetail.roofFlueExitPosition} \n`;
    }

    if (job.jobDetail.flueDoorWindowDistance.length > 0) {
      infoText += `Flue distance from opening: ${job.jobDetail.flueDoorWindowDistance}`;
    }

    return infoText;
  };

  const renderCustomerName = () => {
    return `Name: ${job.name.firstName} ${job.name.lastName}`;
  };

  const renderPostcode = () => {
    return `Postcode: ${job.address.postcode}`;
  };

  return (
    <div className="card-body">
      <div className="row">
        <div className={options.showPersonalInfo === true ? "col-md-6" : "display-none"}>
          <p className="fw-bold card-title">{options.showPersonalInfo === true ? renderCustomerName() : ""}</p>
        </div>
        <div className="col-md-6">
          <p className="fw-bold">
            Install Due:{" "}
            {new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(
              new Date(job.installDate)
            )}
          </p>
        </div>
        <div className={options.showPersonalInfo === false ? "col-md-6" : "display-none"}>
          <p className="fw-bold card-title">{options.showPersonalInfo === false ? renderPostcode() : ""}</p>
        </div>
        <hr />
      </div>
      <div className="card-text">
        <div className="row">
          {options.showPersonalInfo === true ? <CustomerPersonalDetail job={job} /> : ""}
          <div className={options.showPersonalInfo === true ? "col-md-3" : "col-md-6"}>
            <div className="form-group mb-3">
              <label htmlFor="boilerMake">Boiler Make</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="boilerMake"
                value={job.boiler.data.attributes.make}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="boilerModel">Boiler Model</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="boilerModel"
                value={job.boiler.data.attributes.model}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="boilerType">Boiler Type</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="boilerType"
                value={job.boiler.data.attributes.type}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="boilerOutput">Boiler Output</label>
              <input
                className="form-control"
                type="text"
                readOnly={true}
                id="boilerOutput"
                value={job.boiler.data.attributes.output + "kW"}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="address">House Info</label>
              <textarea
                className="form-control"
                readOnly={true}
                id="house"
                rows="5"
                value={`${job.house.houseType}${"\n"}${
                  job.house.floorNumber.length > 0 ? "Floor Number is " + job.house.floorNumber + "\n" : ""
                }${job.house.fuelType + " fuel"}${"\n"}${
                  job.house.bedrooms + " bedroom" + (job.house.bedrooms != responseTypes().NUMBER_ONE ? "s" : "")
                }${"\n"}${
                  job.house.bathtubs + " bath tub" + (job.house.bathtubs != responseTypes().NUMBER_ONE ? "s" : "")
                }${"\n"}${
                  job.house.showers + " shower" + (job.house.showers != responseTypes().NUMBER_ONE ? "s" : "")
                }`}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label htmlFor="address">Job Info</label>
              <textarea className="form-control" readOnly={true} id="house" rows="15" value={renderJobInfoText()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JobDetails.propTypes = {
  job: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default JobDetails;
