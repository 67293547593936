import React from "react";
import PropTypes from "prop-types";
import JobDetails from "./JobDetail";
import responseTypes from "../../utils/responseTypes";

const MyCompletedJobs = ({ myCompletedJobs }) => {
  const renderJobStatus = (status) => {
    switch (status) {
      case responseTypes().STATUS_COMPLETE:
        return "Completed";

      case responseTypes().STATUS_QA_PENDING:
        return "QA Pending";

      case responseTypes().STATUS_CHECKED:
        return "Checked";
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <p className="lead fw-bold">Jobs Completed in Last 30 Days</p>
        {myCompletedJobs.length === 0 ? (
          <p className="fw-bold">You have no jobs to display</p>
        ) : (
          myCompletedJobs.map((job) => {
            return (
              <div key={job.orderNumber} className="card mb-3">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="align-middle my-2">
                        <span className="lead">Order Number: {job.orderNumber}</span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="align-middle my-2 text-end">
                        <span className="lead fw-bold">
                          Order Value:{" "}
                          {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
                            job.engineerTotal
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <JobDetails job={job} options={{ showPersonalInfo: true }} />
                <div className="card-footer">
                  <div className="row">
                    <div className="col-xs-12 col-md-12">
                      <p className="fw-bold">
                        <span className="text-muted">Current Status:</span> {renderJobStatus(job.orderStatus)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <p>
                        <span className="text-muted">Date Installed:</span>{" "}
                        {new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(
                          new Date(job.completionDetail.installationCompletionDate)
                        )}
                      </p>
                      <p>
                        <span className="text-muted">Date Submitted for QA:</span>{" "}
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(job.completionDetail.dateSubmittedForQA))}
                      </p>
                      <p className="">
                        <span className="text-muted">QA Check Date:</span>{" "}
                        {job.completionDetail.qaCheckDate
                          ? new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "2-digit" }).format(
                              new Date(job.completionDetail.qaCheckDate)
                            )
                          : "Awaiting QA"}
                      </p>
                      <p>
                        <span className="text-muted">QA Passed:</span>{" "}
                        {job.completionDetail.qaPassed
                          ? "Yes"
                          : job.completionDetail.qaPassed === false
                          ? "No"
                          : "Awaiting QA"}
                      </p>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <p className="text-muted mb-0">QA Comments</p>
                      <textarea
                        style={{ width: "100%", height: "150px" }}
                        disabled={true}
                        defaultValue={job.completionDetail.qaComments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

MyCompletedJobs.propTypes = {
  myCompletedJobs: PropTypes.array.isRequired,
};

export default MyCompletedJobs;
