import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as engineerApi from "../../api/engineerApi";

export function loadProfileSuccess(profile) {
  return { type: types.LOAD_ENGINEER_SUCCESS, profile: profile };
}

export function loadProfile(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());

    const profile = auth.getProfile(() => {});

    return engineerApi
      .getEngineerById(auth, profile)
      .then((profileFound) => {
        dispatch(loadProfileSuccess(profileFound));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}
