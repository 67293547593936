import React from "react";
import PropTypes from "prop-types";
import JobDetails from "./JobDetail";

const NewJobs = ({ jobsToList, claimJobHandler }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <p className="lead fw-bold">New Jobs</p>
        {jobsToList.length === 0 ? (
          <h4>There are currently no jobs to display</h4>
        ) : (
          jobsToList.map((job) => {
            return (
              <div key={job.orderNumber} className="card mb-3">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-5">
                      <p className="align-middle my-2">
                        <span className="lead">Order Number: {job.orderNumber}</span>
                      </p>
                    </div>
                    <div className="col-md-5">
                      <p className="align-middle my-2 text-end">
                        <span className="lead fw-bold">
                          Job Value:{" "}
                          {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
                            job.engineerTotal
                          )}
                        </span>
                      </p>
                    </div>

                    <div className="col-md-2">
                      <button
                        className="btn btn-success align-middle my-1"
                        value={job.orderNumber}
                        onClick={claimJobHandler}
                      >
                        Claim Job
                      </button>
                    </div>
                  </div>
                </div>
                <JobDetails job={job} options={{ showPersonalInfo: false }} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

NewJobs.propTypes = {
  jobsToList: PropTypes.array.isRequired,
  claimJobHandler: PropTypes.func.isRequired,
};

export default NewJobs;
