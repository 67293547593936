import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Documents from "./Documents";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";

import * as profileActions from "../../redux/actions/profileActions";
import EngineerPersonalDetail from "./EngineerPersonalDetail";
import EditPersonalDetails from "./EditPersonalDetails";
import AreaCovered from "./AreaCovered";
import Availability from "./Availability";
import { getAvailableAreas, updateStandardDocument, updatePersonalDetails } from "../../api/engineerApi";
import * as engineerApi from "../../api/engineerApi";

const VIEW_PERSONAL_DETAIL = "detail";
const VIEW_AREA_COVERED = "area";
const VIEW_AVAILABILITY = "availability";
const VIEW_DOCUMENTS = "documents";

export function EngineersPage({ profile, loadProfile, auth }) {
  const [viewArea, setViewArea] = useState(VIEW_PERSONAL_DETAIL);
  const [allCoverageAreas, setAllCoverageAreas] = useState();
  const [allRegions, setAllRegions] = useState([]);
  const [personalDetailsEditMode, setPersonalDetailsEditMode] = useState(false);
  const [localProfile, setLocalProfile] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [personalDetailsFormError, setPersonalDetailsFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState([]);

  useEffect(async () => {
    const coveredAreas = await getAvailableAreas(auth);
    const allRegions = [...new Set(coveredAreas.map((area) => area.attributes.region))];

    setAllCoverageAreas(coveredAreas);
    setAllRegions(allRegions);
  }, []);

  useEffect(async () => {
    if (profile && Object.keys(profile).length === 0) {
      await loadProfile(auth).catch((error) => {
        console.log("error loading profile");
        throw error;
      });
    } else {
      copyProfile();
    }
  }, [profile]);

  function copyProfile() {
    // Create a copy of the Profile object to handle editing
    const localCopy = {
      engineerImageUrl: profile.engineerImage.data ? profile.engineerImage.data.attributes.url : null,
      title: profile.name.title,
      firstName: profile.name.firstName,
      lastName: profile.name.lastName,
      email: profile.email,
      mobileNumber: profile.mobileNumber,
      gasSafeLicenseNumber: profile.gasSafeLicenseNumber,
      companyName: profile.companyName,
      address1: profile.address.address1,
      address2: profile.address.address2,
      address3: profile.address.address3,
      city: profile.address.city,
      county: profile.address.county,
      postcode: profile.address.postcode,
      companyGasSafeNumber: profile.companyGasSafeNumber,
      companyType: profile.companyType,
      companyNumber: profile.companyNumber,
      vatRegistered: profile.vatRegistered,
      vatNumber: profile.vatNumber,
    };
    setLocalProfile(localCopy);
    setProfileImage(null);
  }

  function viewClickHandler(event) {
    event.preventDefault();

    setViewArea(event.target.value);
  }

  function handleDetailChange(event) {
    const { name, value } = event.target;
    setLocalProfile((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  }

  const uploadProfileImageToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setProfileImage(event.target.files[0]);
    }
  };

  function checkDetailsFormForErrors() {
    let formError = false;
    const formErrorMessages = [];

    setFormErrorMessage([]);

    if (!localProfile.engineerImageUrl && !profileImage) {
      // no profile image has been supplied
      formError = true;
      formErrorMessages.push("Please provide a profile image of yourself");
    }

    if (localProfile.firstName === "") {
      formError = true;
      formErrorMessages.push("Please provide your first name");
    }

    if (localProfile.lastName === "") {
      formError = true;
      formErrorMessages.push("Please provide your last name");
    }

    if (localProfile.email === "") {
      formError = true;
      formErrorMessages.push("Please provide your email address");
    }

    if (localProfile.mobileNumber === "") {
      formError = true;
      formErrorMessages.push("Please provide your mobile telephone number");
    }

    if (localProfile.gasSafeLicenseNumber === "") {
      formError = true;
      formErrorMessages.push("Please provide your Gas Safe License Number");
    }

    if (localProfile.companyName === "") {
      formError = true;
      formErrorMessages.push("Please provide your company name");
    }

    if (
      localProfile.address1 === "" ||
      localProfile.city === "" ||
      localProfile.county === "" ||
      localProfile.postcode === ""
    ) {
      formError = true;
      formErrorMessages.push("Please provide your company address details");
    }

    if (localProfile.companyGasSafeNumber === "") {
      formError = true;
      formErrorMessages.push("Please provide your company gas safe number");
    }

    if (localProfile.companyType === "limitedCompany" && localProfile.companyNumber === "") {
      formError = true;
      formErrorMessages.push(
        "You have indicated your company is a limited company, please provide your Limited Company Number"
      );
    }

    if (
      (localProfile.vatRegistered === "true" || localProfile.vatRegistered === true) &&
      localProfile.vatNumber === ""
    ) {
      formError = true;
      formErrorMessages.push(
        "You have indicated your company is VAT Registered, please provide your VAT Registration Number"
      );
    }

    setFormErrorMessage(formErrorMessages);

    return formError;
  }

  function remapLocalProfile() {
    const returnProfile = {
      name: {
        title: localProfile.title,
        firstName: localProfile.firstName,
        lastName: localProfile.lastName,
      },
      email: localProfile.email,
      mobileNumber: localProfile.mobileNumber,
      gasSafeLicenseNumber: localProfile.gasSafeLicenseNumber,
      companyName: localProfile.companyName,
      address: {
        address1: localProfile.address1,
        address2: localProfile.address2,
        address3: localProfile.address3,
        city: localProfile.city,
        county: localProfile.county,
        postcode: localProfile.postcode,
      },
      companyGasSafeNumber: localProfile.companyGasSafeNumber,
      companyType: localProfile.companyType,
      companyNumber: localProfile.companyNumber,
      vatRegistered: localProfile.vatRegistered,
      vatNumber: localProfile.vatNumber,
    };

    return returnProfile;
  }

  async function handleDetailSave(event) {
    event.preventDefault();

    const formError = checkDetailsFormForErrors();

    if (!formError) {
      // Do the save
      if (profileImage) {
        // New profile image provided, save it
        await updateStandardDocument(auth, profileImage, null, engineerApi.PROFILE_IMAGE);
      }

      const saveProfile = remapLocalProfile();
      await updatePersonalDetails(auth, saveProfile);
      await loadProfile(auth);

      setPersonalDetailsEditMode(false);
    } else {
      // Form errors exists
      setPersonalDetailsFormError(formError);
    }
  }

  function setDetailsEditMode() {
    setPersonalDetailsEditMode(true);
  }

  function handleDetailCancel(event) {
    event.preventDefault();
    setPersonalDetailsEditMode(false);
    copyProfile(); //Reset the local profile object in case changes have been made
  }

  function renderView() {
    switch (viewArea) {
      case VIEW_AREA_COVERED:
        return (
          <AreaCovered
            userProfile={profile}
            regions={allRegions}
            areas={allCoverageAreas}
            profileAuth={auth}
            loadProfile={loadProfile}
          />
        );
      case VIEW_AVAILABILITY:
        return <Availability auth={auth} />;
      case VIEW_DOCUMENTS:
        return <Documents profile={profile} auth={auth} loadProfile={loadProfile} />;
      default:
        if (personalDetailsEditMode) {
          return (
            <EditPersonalDetails
              userProfile={localProfile}
              saveHandler={handleDetailSave}
              changeHandler={handleDetailChange}
              uploadProfileImageHandler={uploadProfileImageToClient}
              formError={personalDetailsFormError}
              formErrorMessage={formErrorMessage}
              cancelHandler={handleDetailCancel}
            />
          );
        } else {
          return <EngineerPersonalDetail userProfile={localProfile} switchEditMode={setDetailsEditMode} />;
        }
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>My Details</h2>
          <p>Amend contact, availability and areas covered</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <button className="btn btn-primary me-2 mb-2" value={VIEW_PERSONAL_DETAIL} onClick={viewClickHandler}>
            Personal Details
          </button>
          <button className="btn btn-primary me-2 mb-2" value={VIEW_AREA_COVERED} onClick={viewClickHandler}>
            Areas Covered
          </button>
          <button className="btn btn-primary me-2 mb-2" value={VIEW_AVAILABILITY} onClick={viewClickHandler}>
            Availability
          </button>
          <button className="btn btn-primary me-2 mb-2" value={VIEW_DOCUMENTS} onClick={viewClickHandler}>
            Documents
          </button>
        </div>
      </div>
      {Object.keys(localProfile).length === 0 ? <Spinner /> : renderView()}
    </div>
  );
}

EngineersPage.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

const mapDispatchToProps = {
  loadProfile: profileActions.loadProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineersPage);
