import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import SignaturePad from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import responseTypes from "../../utils/responseTypes";

export function CompleteJobMenu({
  jobToComplete,
  submitSatisfactionHandler,
  productSerialsHandler,
  benchmarkSubmitHandler,
  uploadBenchmarkToClientFunc,
  gasSignOffHandler,
  uploadCP1ToClientFunc,
  electricalSignOffHandler,
  installationImageSignOffHandler,
  uploadInstallationImageToClientFunc,
  addInstallationImageToJob,
  removeInstallationFromJob,
  errorMessage,
  setError,
}) {
  const today = new Date();
  const [signatureUrl, setSignatureUrl] = useState(null);

  useEffect(() => {
    if (signatureUrl != null && !jobToComplete.completionDetail.customerSatisfied) {
      submitSatisfactionHandler(signatureUrl);
    }
  }, [signatureUrl]);

  useEffect(() => {}, [jobToComplete]);

  const dbSignatureDate = new Date(jobToComplete.completionDetail.customerSignatureDate);

  const sigCanvas = useRef({});

  const clearCanvas = () => sigCanvas.current.clear();
  const saveCanvas = async () => {
    if (sigCanvas.current.isEmpty()) {
      setError("customerSatisfaction", "noSignature", "Please sign the form before submitting");
    } else {
      setSignatureUrl(sigCanvas.current.getCanvas().toDataURL("image/png"));
    }
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="accordion" id="menuAccordion">
          <div className="accordion-item" id="customerSatisfaction">
            <h2 className="accordion-header" id="headerCustSatisfaction">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCustSatisfaction"
                aria-expanded="false"
                aria-controls="collapseCustSatisfaction"
              >
                1. Customer Satisfaction {jobToComplete.completionDetail.customerSatisfied ? " - Complete " : ""}
                {jobToComplete.completionDetail.customerSatisfied ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseCustSatisfaction"
              className={
                jobToComplete.completionDetail.customerSatisfied
                  ? "accordion-collapse collapse"
                  : "accordion-collapse collapse show"
              }
              aria-labelledby="headerCustSatisfaction"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                <p className="text-muted mb-1">Engineer Note</p>
                <p className="text-muted">
                  This satisfaction note must be signed by the customer on completion of the work. This signature will
                  complete the customer agreement. If the customer is not present please contact us on xxxxx xxxxxx
                </p>
                <p className="fw-bold">To be signed by customer</p>
                <p>
                  By Signing, you agree that the job has been completed to your satisfaction and according to the
                  contract. After you have signed the document and if you have a finance agreement, the funds will be
                  disbursed to the merchant from the lender.
                </p>
                <div className="form-group mb-3">
                  <label htmlFor="name" className="text-muted">
                    Customer Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    readOnly={true}
                    id="name"
                    value={`${jobToComplete.name.firstName} ${jobToComplete.name.lastName}`}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="signDate" className="text-muted">
                    Date
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    readOnly={true}
                    id="signDate"
                    value={
                      jobToComplete.completionDetail.customerSatisfied
                        ? dbSignatureDate.toLocaleDateString()
                        : today.toLocaleDateString()
                    }
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="signature" className="text-muted">
                    Customer Signature
                  </label>
                  {!jobToComplete.completionDetail.customerSatisfied ? (
                    <SignaturePad ref={sigCanvas} id="signature" canvasProps={{ className: "signatureCanvas" }} />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        jobToComplete.completionDetail.customerSatisfactionSignature.data.attributes.url
                      }
                      className="signatureCanvas mb-2"
                      disabled={true}
                    ></img>
                  )}
                  <div className="text-end">
                    <button
                      className="btn btn-danger me-3"
                      onClick={clearCanvas}
                      disabled={jobToComplete.completionDetail.customerSatisfied}
                    >
                      Clear Signature
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={saveCanvas}
                      disabled={jobToComplete.completionDetail.customerSatisfied}
                    >
                      Submit Customer Satisfaction
                    </button>
                    {errorMessage.map((errorMsg) => {
                      if (errorMsg.section === "customerSatisfaction") {
                        return (
                          <div key={errorMsg.id} className="text-center mt-3">
                            <p className="text-danger fw-bold">*{errorMsg.message}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item" id="productSerials">
            <h2 className="accordion-header" id="headerProductSerials">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProductSerials"
                aria-expanded="false"
                aria-controls="collapseProductSerials"
              >
                2. Product Serial Numbers {jobToComplete.completionDetail.productSerialsComplete ? " - Complete " : ""}
                {jobToComplete.completionDetail.productSerialsComplete ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseProductSerials"
              className="accordion-collapse collapse"
              aria-labelledby="headerProductSerials"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                <p className="text-muted mb-1">Enter details of product serial numbers</p>
                <form onSubmit={productSerialsHandler}>
                  <div className="form-group mb-3">
                    <label htmlFor="boilerSerial">Boiler Serial Number</label>
                    <input
                      className="form-control"
                      type="text"
                      id="boilerSerial"
                      name="boilerSerial"
                      disabled={jobToComplete.completionDetail.productSerialsComplete}
                      defaultValue={
                        jobToComplete.completionDetail.productSerialsComplete
                          ? jobToComplete.completionDetail.boilerSerialNumber
                          : ""
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="filterSerial">Filter Serial Number</label>
                    <input
                      className="form-control"
                      type="text"
                      id="filterSerial"
                      name="filterSerial"
                      disabled={jobToComplete.completionDetail.productSerialsComplete}
                      defaultValue={
                        jobToComplete.completionDetail.productSerialsComplete
                          ? jobToComplete.completionDetail.filterSerialNumber
                          : ""
                      }
                    />
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      onClick={productSerialsHandler}
                      className="btn btn-primary"
                      disabled={jobToComplete.completionDetail.productSerialsComplete}
                    >
                      Submit Serial Numbers
                    </button>
                    {errorMessage.map((errorMsg) => {
                      if (errorMsg.section === "productSerials") {
                        return (
                          <div key={errorMsg.id} className="text-center mt-3">
                            <p className="text-danger fw-bold">*{errorMsg.message}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="accordion-item" id="benchmark">
            <h2 className="accordion-header" id="headerBenchmark">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseBenchmark"
                aria-expanded="false"
                aria-controls="collapseBenchmark"
              >
                3. Completed Benchmark {jobToComplete.completionDetail.benchmarkComplete ? " - Complete " : ""}
                {jobToComplete.completionDetail.benchmarkComplete ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseBenchmark"
              className="accordion-collapse collapse"
              aria-labelledby="headerBenchmark"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                {jobToComplete.completionDetail.benchmarkComplete ? (
                  <img
                    className="mb-2"
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      jobToComplete.completionDetail.completedBenchmarkImage.data.attributes.url
                    }
                    style={{ height: 300 }}
                  />
                ) : (
                  <form onSubmit={benchmarkSubmitHandler}>
                    <div className="form-group mb-3">
                      <label htmlFor="benchmarkImage">Upload an image of the benchmark</label>
                      <input
                        type="file"
                        className="form-control"
                        id="benchmarkImage"
                        name="benchmarkImage"
                        placeholder="upload image of benchmark"
                        onChange={uploadBenchmarkToClientFunc}
                        accept="image/*, .pdf"
                        required
                      />
                    </div>
                    <div className="text-end">
                      <button
                        onClick={benchmarkSubmitHandler}
                        type="submit"
                        className="btn btn-primary"
                        disabled={jobToComplete.completionDetail.benchmarkComplete}
                      >
                        Submit Benchmark
                      </button>
                      {errorMessage.map((errorMsg) => {
                        if (errorMsg.section === "benchmark") {
                          return (
                            <div key={errorMsg.id} className="text-center mt-3">
                              <p className="text-danger fw-bold">*{errorMsg.message}</p>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div className="accordion-item" id="gasSignOff">
            <h2 className="accordion-header" id="headerGasSignOff">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGasSignOff"
                aria-expanded="false"
                aria-controls="collapseSignOff"
              >
                4. Gas Installation Sign Off {jobToComplete.completionDetail.gasSignOffComplete ? " - Complete " : ""}
                {jobToComplete.completionDetail.gasSignOffComplete ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseGasSignOff"
              className="accordion-collapse collapse"
              aria-labelledby="headerGasSignOff"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                <form onSubmit={gasSignOffHandler}>
                  <div className="form-group mb-3" id="gasVisualInspectionQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Visual inspection satisfactory?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasVisualInspectionYes"
                          name="gasVisualInspection"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasVisualInspectionOk}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasVisualInspectionYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasVisualInspectionNo"
                          name="gasVisualInspection"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasVisualInspectionOk
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasVisualInspectionNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="gasTightnessTestQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Gas tightness test satisfactory?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasTightnessTestYes"
                          name="gasTightnessTest"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasTightnessTestOk}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasTightnessTestYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasTightnessTestNo"
                          name="gasTightnessTest"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasTightnessTestOk
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasTightnessTestNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="gasEmergencyControlsAccessQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Are the emergency controls accessible?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasEmergencyControlsAccessYes"
                          name="gasEmergencyControlsAccess"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasEmergencyControlAccessible}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasEmergencyControlsAccessYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasEmergencyControlsAccessNo"
                          name="gasEmergencyControlsAccess"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasEmergencyControlAccessible
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasEmergencyControlsAccessNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="gasEquipotentialQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Equipotential satisfactory?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasEquipotentialYes"
                          name="gasEquipotential"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasEquipotentialBondingOk}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasEquipotentialYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasEquipotentialNo"
                          name="gasEquipotential"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasEquipotentialBondingOk
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasEquipotentialNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="gasPipeworkWarningQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Pipework warning notice issued?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasPipeworkWarningYes"
                          name="gasPipeworkWarning"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasPipeworkWarningIssued}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasPipeworkWarningYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasPipeworkWarningNo"
                          name="gasPipeworkWarning"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasPipeworkWarningIssued
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="gasPipeworkWarningNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="adeyProsenseInstalledQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Adey Prosense&reg; installed and connected to customer&apos;s wifi?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="adeyProsenseInstalledYes"
                          name="adeyProsenseInstalled"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.gasAdeyProsenseOk}
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="adeyProsenseInstalledYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="adeyProsenseInstalledNo"
                          name="adeyProsenseInstalled"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.gasSignOffComplete
                              ? !jobToComplete.completionDetail.gasAdeyProsenseOk
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.gasSignOffComplete}
                        />
                        <label htmlFor="adeyProsenseInstalledNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="uploadCP1Question">
                    {jobToComplete.completionDetail.gasSignOffComplete ? (
                      <>
                        <p className="text-muted">Uploaded CP1 Certificate</p>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            jobToComplete.completionDetail.gasCP1Image.data.attributes.url
                          }
                          style={{ height: 300 }}
                        />
                      </>
                    ) : (
                      <>
                        <label htmlFor="gasCP1Image">Upload an image of completed CP1 Certificate</label>
                        <input
                          type="file"
                          className="form-control"
                          id="gasCP1Image"
                          name="gasCP1Image"
                          placeholder="upload image of CP1 Certificate"
                          onChange={uploadCP1ToClientFunc}
                          accept="image/*, .pdf"
                          required
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group mb-3" id="gasEngineerNotesQuestion">
                    <label htmlFor="gasEngineerNotes">Engineer Notes</label>
                    <textarea
                      className="form-control"
                      placeholder="enter any additional comments here"
                      id="gasEngineerNotes"
                      name="gasEnginnerNotes"
                      style={{ height: "150px" }}
                      defaultValue={jobToComplete.completionDetail.gasEngineerNotes}
                      disabled={jobToComplete.completionDetail.gasSignOffComplete}
                    />
                  </div>
                  <div className="text-end" id="gasSignOffSubmit">
                    <button
                      onClick={gasSignOffHandler}
                      type="submit"
                      className="btn btn-primary"
                      disabled={jobToComplete.completionDetail.gasSignOffComplete}
                    >
                      Submit Gas Sign Off
                    </button>
                    {errorMessage.map((errorMsg) => {
                      if (errorMsg.section === "gasSignOff") {
                        return (
                          <div key={errorMsg.id} className="text-center mt-3">
                            <p className="text-danger fw-bold">*{errorMsg.message}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="accordion-item" id="electricalSignOff">
            <h2 className="accordion-header" id="headerElectricalSignOff">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseElectricalSignOff"
                aria-expanded="false"
                aria-controls="collapseElectricalSignOff"
              >
                5. Electrical Installation Sign Off{" "}
                {jobToComplete.completionDetail.electricalSignOffComplete ? " - Complete " : ""}
                {jobToComplete.completionDetail.electricalSignOffComplete ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseElectricalSignOff"
              className="accordion-collapse collapse"
              aria-labelledby="headerElectricalSignOff"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                <form onSubmit={electricalSignOffHandler}>
                  <div className="form-group mb-3" id="elecBoilerConnectedQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Boiler connected to power supply?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecBoilerConnectedYes"
                          name="elecBoilerConnected"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.electricBoilerConnected}
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecBoilerConnectedYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecBoilerConnectedNo"
                          name="elecBoilerConnected"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete
                              ? !jobToComplete.completionDetail.electricBoilerConnected
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecBoilerConnectedNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="gasBondedQuestion">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <p>Gas correctly bonded (with 600mm) and tightly clamped with a safety label visible?</p>
                      </div>
                      <div className="col-xs-12 col-md-6 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasBondedYes10mm"
                          name="gasBonded"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_BONDED_YES10MM}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricBondedAndClamped ===
                              responseTypes().ELECTRIC_BONDED_YES10MM
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="gasBondedYes10mm" className="btn btn-outline-primary">
                          Yes, 10mm
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasBondedYes6mm"
                          name="gasBonded"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_BONDED_YES6MM}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricBondedAndClamped ===
                              responseTypes().ELECTRIC_BONDED_YES6MM
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="gasBondedYes6mm" className="btn btn-outline-primary">
                          Yes, 6mm
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasBondedNo"
                          name="gasBonded"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_BONDED_NO}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricBondedAndClamped ===
                              responseTypes().ELECTRIC_BONDED_NO
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="gasBondedNo" className="btn btn-outline-primary">
                          No
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="gasBondedNA"
                          name="gasBonded"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_BONDED_NA}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricBondedAndClamped ===
                              responseTypes().ELECTRIC_BONDED_NA
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="gasBondedYesNA" className="btn btn-outline-primary">
                          N/A
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="cablesClippedQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Are all cables securely clipped or in trunking where possible?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="cablesClippedYes"
                          name="cablesClipped"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.electricClippedAndTrunked}
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="cablesClippedYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="cablesClippedNo"
                          name="cablesClipped"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete
                              ? !jobToComplete.completionDetail.electricClippedAndTrunked
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="cablesClippedNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="elecSleevedQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Electrical connections sleeved (where possible) and their intended use made clear?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecSleevedYes"
                          name="elecSleeved"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.electricSleevedAndClear}
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecSleevedYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecSleevedNo"
                          name="elecSleeved"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete
                              ? !jobToComplete.completionDetail.electricSleevedAndClear
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecSleevedNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="elecDisconnectionQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Where is the means of disconnection from the power supply?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecDisconnectionPlug"
                          name="elecDisconnection"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_DISCONNECT_PLUG}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricDisconnection ===
                              responseTypes().ELECTRIC_DISCONNECT_PLUG
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecDisconnectionPlug" className="btn btn-outline-primary">
                          Plug
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="elecDisconnectionSpur"
                          name="elecDisconnection"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_DISCONNECT_SPUR}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricDisconnection ===
                              responseTypes().ELECTRIC_DISCONNECT_SPUR
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="elecDisconnectionSpur" className="btn btn-outline-primary">
                          Spur
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="boilerEarthQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Has the boiler got an earth directly connected to the isolation point (plug/spur)?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="boilerEarthYes"
                          name="boilerEarth"
                          autoComplete="off"
                          value={true}
                          defaultChecked={jobToComplete.completionDetail.electricDirectEarthToIsolation}
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="boilerEarthYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="boilerEarthNo"
                          name="boilerEarth"
                          autoComplete="off"
                          value={false}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete
                              ? !jobToComplete.completionDetail.electricDirectEarthToIsolation
                              : ""
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="boilerEarthNo" className="btn btn-outline-primary">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="heatingControlsQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Have heating controls been installed within manufacturers guidelines?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsYes"
                          name="heatingControls"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONTROLS_YES}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricHeatingControlsOk ===
                              responseTypes().ELECTRIC_HEATING_CONTROLS_YES
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsNo"
                          name="heatingControls"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONTROLS_NO}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricHeatingControlsOk ===
                              responseTypes().ELECTRIC_HEATING_CONTROLS_NO
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsNo" className="btn btn-outline-primary">
                          No
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsNA"
                          name="heatingControls"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONTROLS_NA}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricHeatingControlsOk ===
                              responseTypes().ELECTRIC_HEATING_CONTROLS_NA
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsNA" className="btn btn-outline-primary">
                          N/A
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3" id="heatingControlsDemoQuestion">
                    <div className="row">
                      <div className="col-8">
                        <p>Has the customer been shown how to operate the heating controls?</p>
                      </div>
                      <div className="col-4 text-end">
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsDemoYes"
                          name="heatingControlsDemo"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_YES}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricCustomerShownHeatingControls ===
                              responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_YES
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsDemoYes" className="btn btn-outline-primary">
                          Yes
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsDemoNo"
                          name="heatingControlsDemo"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_NO}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricCustomerShownHeatingControls ===
                              responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_NO
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsDemoNo" className="btn btn-outline-primary">
                          No
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="heatingControlsDemoNA"
                          name="heatingControlsDemo"
                          autoComplete="off"
                          value={responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_NA}
                          defaultChecked={
                            jobToComplete.completionDetail.electricalSignOffComplete &&
                            jobToComplete.completionDetail.electricCustomerShownHeatingControls ===
                              responseTypes().ELECTRIC_HEATING_CONROLS_DEMO_NA
                              ? true
                              : false
                          }
                          disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                        />
                        <label htmlFor="heatingControlsDemoNA" className="btn btn-outline-primary">
                          N/A
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-end" id="submitElectricalSignOff">
                    <button
                      onClick={electricalSignOffHandler}
                      type="submit"
                      className="btn btn-primary"
                      disabled={jobToComplete.completionDetail.electricalSignOffComplete}
                    >
                      Submit Electrical Sign Off
                    </button>
                    {errorMessage.map((errorMsg) => {
                      if (errorMsg.section === "electricalSignOff") {
                        return (
                          <div key={errorMsg.id} className="text-center mt-3">
                            <p className="text-danger fw-bold">*{errorMsg.message}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="accordion-item" id="installationImages">
            <h2 className="accordion-header" id="headerInstallationImages">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseInstallationImages"
                aria-expanded="false"
                aria-controls="collapseInstallationImages"
              >
                6. Upload Installation Images{" "}
                {jobToComplete.completionDetail.installationImagesComplete ? " - Complete " : ""}
                {jobToComplete.completionDetail.installationImagesComplete ? (
                  <FontAwesomeIcon icon={faCircleCheck} className="fa ms-2 me-2" />
                ) : (
                  ""
                )}
              </button>
            </h2>
            <div
              id="collapseInstallationImages"
              className="accordion-collapse collapse"
              aria-labelledby="headerInstallationImages"
              data-bs-parent="#menuAccordion"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-xs-12 col-md-8 border-right">
                    <form onSubmit={addInstallationImageToJob}>
                      <div className="form-group mb-3" id="selectInstallationImage">
                        <label htmlFor="benchmarkImage">Select image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="installationImage"
                          name="installationImage"
                          placeholder="upload image of installation"
                          onChange={uploadInstallationImageToClientFunc}
                          accept="image/*, .pdf"
                          required
                          disabled={jobToComplete.completionDetail.installationImagesComplete}
                        />
                      </div>
                      <div className="form-group mb-3" id="selectInstallationImageType">
                        <label htmlFor="imageType">What is this an image of?</label>
                        <select
                          className="form-select"
                          id="imageType"
                          name="imageType"
                          disabled={jobToComplete.completionDetail.installationImagesComplete}
                        >
                          <option value="Boiler (Front)">Boiler (Front)</option>
                          <option value="Boiler (Underneath)">Boiler (Underneath)</option>
                          <option value="Boiler Filter">Boiler Filter</option>
                          <option value="Boiler Flue">Boiler Flue</option>
                          <option value="Condensate Termination">Condensate Termination</option>
                          <option value="Condensate Through Wall Greater Than 32mm">
                            Condensate Through Wall Greater Than 32mm
                          </option>
                          <option value="Conversion Pipework 1">Conversion Pipework 1</option>
                          <option value="Conversion Pipework 2">Conversion Pipework 2</option>
                          <option value="Data Badge Serial Number">Data Badge Serial Number</option>
                          <option value="FGA Readings">FGA Readings</option>
                          <option value="Filter Badge Serial Number">Filter Badge Serial Number</option>
                          <option value="Flue Integrity Test">Flue Integrity Test</option>
                          <option value="Flue Sealed">Flue Sealed</option>
                          <option value="Flue Termination">Flue Termination</option>
                          <option value="Gas Integrity Test with Gas Analyser">
                            Gas Integrity Test with Gas Analyser
                          </option>
                          <option value="Gas Meter">Gas Meter</option>
                          <option value="Heating Controls">Heating Controls</option>
                          <option value="Hot Water Tank">Hot Water Tank</option>
                          <option value="System Filter">System Filter</option>
                          <option value="Bonding (Gas)">Bonding (Gas)</option>
                          <option value="Bonding (Water)">Bonding (Water)</option>
                          <option value="Cabling (Trunk/Clipped)">Cabling (Trunk/Clipped)</option>
                          <option value="Flex Termination">Flex Termination</option>
                          <option value="Fuse Spur">Fuse Spur</option>
                          <option value="Power Supply Connection">Power Supply Connection</option>
                        </select>
                      </div>
                      <div className="text-end" id="uploadImage">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={addInstallationImageToJob}
                          disabled={jobToComplete.completionDetail.installationImagesComplete}
                        >
                          Upload Image
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-xs-12 col-md-4" id="uploadedImagesDisplay">
                    <p className="text-muted">Uploaded Images</p>
                    {jobToComplete.completionDetail.completionImages.map((image) => {
                      return (
                        <div key={image.id}>
                          <img
                            className="mb-0"
                            src={process.env.REACT_APP_IMAGE_URL + image.installationImage.data.attributes.url}
                            width={
                              image.installationImage.data.attributes.width >=
                              image.installationImage.data.attributes.height
                                ? 200
                                : ""
                            }
                            height={
                              image.installationImage.data.attributes.width <=
                              image.installationImage.data.attributes.height
                                ? 200
                                : ""
                            }
                          />
                          <p className="text-muted mb-2 mt-1">
                            <button
                              className="btn-outline-danger me-2"
                              name="removeImage"
                              id="removeImage"
                              value={image.id}
                              onClick={removeInstallationFromJob}
                              disabled={jobToComplete.completionDetail.installationImagesComplete}
                            >
                              Remove
                            </button>
                            {image.imageTitle}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="text-end mt-3 border-top">
                  <button
                    onClick={installationImageSignOffHandler}
                    className="btn btn-primary mt-3"
                    disabled={jobToComplete.completionDetail.installationImagesComplete}
                  >
                    Submit All Installation Images
                  </button>
                  {errorMessage.map((errorMsg) => {
                    if (errorMsg.section === "installationImages") {
                      return (
                        <div key={errorMsg.id} className="text-center mt-3">
                          <p className="text-danger fw-bold">*{errorMsg.message}</p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CompleteJobMenu.propTypes = {
  jobToComplete: PropTypes.object,
  submitSatisfactionHandler: PropTypes.func,
  productSerialsHandler: PropTypes.func,
  benchmarkSubmitHandler: PropTypes.func,
  uploadBenchmarkToClientFunc: PropTypes.func,
  gasSignOffHandler: PropTypes.func,
  uploadCP1ToClientFunc: PropTypes.func,
  electricalSignOffHandler: PropTypes.func,
  installationImageSignOffHandler: PropTypes.func,
  uploadInstallationImageToClientFunc: PropTypes.func,
  addInstallationImageToJob: PropTypes.func,
  removeInstallationFromJob: PropTypes.func,
  errorMessage: PropTypes.array,
  setError: PropTypes.func,
};
