import React, { useEffect } from "react";
import PropTypes from "prop-types";

function Callback(props) {
  // Handle authentication if expected values are in the URL
  useEffect(() => {
    if (/access_token|id_token|error/.test(props.location.hash)) {
      props.auth.handleAuthentication();
    } else {
      throw new Error("Invalid Callback URL");
    }
  }, [props.auth, props.location.hash]);

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}

Callback.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Callback;
