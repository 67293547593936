import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Auth from "../auth/Auth";
import PrivateRoute from "./common/PrivateRoute";
import AuthContext from "./common/AuthContext";

import Menu from "./common/Menu";
import HomePage from "./home/HomePage";
import JobsPage from "./jobs/JobsPage"; // eslint-disable-line import/no-named-as-default
import MessagesPage from "./messages/MessagesPage";
import EngineersPage from "./engineers/EngineersPage"; // eslint-disable-line import/no-named-as-default
import Callback from "./Callback";
import PageNotFound from "./PageNotFound";
import Footer from "./common/Footer";
import HealthCheckPage from "./healthcheck/HealthCheckPage";

function App(props) {
  // eslint-disable-next-line react/prop-types
  const [auth] = useState(new Auth(props.history));
  const [tokenRenewwalComplete, setTokenRenewalComplete] = useState(false);

  useEffect(() => {
    auth.renewToken(() => {
      setTokenRenewalComplete(true);
    });
  });

  // By declaring the AuthContext.Provider, the value of auth will now be available to all child components of App
  if (!tokenRenewwalComplete) return "Loading...";

  return (
    <AuthContext.Provider value={auth}>
      <div className="container-fluid">
        <Menu auth={auth} />
        <Switch>
          <Route exact path="/" render={(props) => <HomePage auth={auth} {...props} exact />} />
          <Route path="/callback" render={(props) => <Callback auth={auth} {...props} exact />} />
          <Route path="/healthcheck" render={(props) => <HealthCheckPage auth={auth} {...props} exact />} />
          <PrivateRoute path="/jobs" component={JobsPage} exact />
          <PrivateRoute path="/messages" component={MessagesPage} exact />
          <PrivateRoute path="/engineer" component={EngineersPage} exact />
          <Route component={PageNotFound} />
        </Switch>
        <ToastContainer autoClose={3000} hideProgressBar />
        <Footer />
      </div>
    </AuthContext.Provider>
  );
}

export default App;
