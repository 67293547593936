import * as types from "../actions/actionTypes";
import initialState from "./initialState";

// Function to read last 8 letters of type and check if it is equal to SUCCESS, returns True or False
function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

// Public Function to handle beginApiCall Action
export default function apiCallStatusReducer(state = initialState.apiCallsInProgress, action) {
  // Check action type and either plus 1 or subtract 1 from state
  if (action.type === types.BEGIN_API_CALL) {
    return state + 1;
  } else if (action.type === types.API_CALL_ERROR || actionTypeEndsInSuccess(action.type)) {
    // An Action type that ends in SUCCESS has been received e.g. LOAD_COURSES_SUCCESS
    return state - 1;
  }

  // default behaviour to return the state
  return state;
}
