import React from "react";

const Footer = function () {
  return (
    <div className="text-center bg-light mt-3 py-5">
      <p className="lead">If you are experiencing issues on this hub</p>
      <p className="">
        Please contact us on tel: <span className="fw-bold">020 4614 2422</span> or email:{" "}
        <span className="fw-bold">eng@boxheat.co.uk</span>
      </p>
    </div>
  );
};

export default Footer;
