import React, { useState } from "react";
import PropType from "prop-types";
import UpdateStandardDocument from "./UpdateStandardDocument";

function EditPersonalDetails({
  userProfile,
  saveHandler,
  changeHandler,
  uploadProfileImageHandler,
  formError,
  formErrorMessage,
  cancelHandler,
}) {
  const profileImageExists = userProfile.engineerImageUrl ? true : false;

  const [updatingProfileImage, setUpdatingProfileImage] = useState(false);

  const handleProfileImgUpdate = (event) => {
    event.preventDefault();
    setUpdatingProfileImage(true);
  };

  return (
    <div>
      {formError ? (
        <div className="border bg-warning p-2 m-3">
          <p className="text-muted">Errors</p>
          {formErrorMessage.map((message) => {
            return (
              <p className="text-muted mb-0" key={message}>
                * {message}
              </p>
            );
          })}
        </div>
      ) : (
        ""
      )}
      <form onSubmit={saveHandler} className="">
        <div className="row">
          <p className="lead fw-bold">Edit Personal Details</p>
          <div className="col-xs-12 col-md-6 mb-3">
            <p className="mb-0 lead">Personal</p>
            <div className="border p-2 shadow">
              <div className="text-center">
                {updatingProfileImage ? (
                  <UpdateStandardDocument
                    documentName="Your Profile"
                    formError={formError}
                    hideExpiry={true}
                    uploadDocToClientFunc={uploadProfileImageHandler}
                  />
                ) : profileImageExists ? (
                  <img
                    className="mb-3"
                    src={process.env.REACT_APP_IMAGE_URL + userProfile.engineerImageUrl}
                    style={{ width: 300, height: 300 }}
                  />
                ) : (
                  <p>No profile image provided</p>
                )}
                {updatingProfileImage ? (
                  ""
                ) : (
                  <button id="profileImageUpdate" className="btn btn-primary" onClick={handleProfileImgUpdate}>
                    Update Profile Image
                  </button>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="title">Title</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={changeHandler}
                  name="title"
                  defaultValue={userProfile.title}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="firstName">First Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  onChange={changeHandler}
                  id="firstName"
                  value={userProfile.firstName}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="lastName">Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  onChange={changeHandler}
                  id="lastName"
                  value={userProfile.lastName}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  onChange={changeHandler}
                  id="email"
                  value={userProfile.email}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="phone">Mobile Number</label>
                <input
                  className="form-control"
                  type="tel"
                  name="mobileNumber"
                  onChange={changeHandler}
                  id="mobileNumber"
                  value={userProfile.mobileNumber}
                  maxLength={13}
                  minLength={11}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="inputGasSafeNumber">Gas Safe License Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="gasSafeLicenseNumber"
                  onChange={changeHandler}
                  id="inputGasSafeNumber"
                  value={userProfile.gasSafeLicenseNumber}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 mb-3">
            <p className="mb-0 lead">Company</p>
            <div className="border p-2 shadow">
              <div className="form-group mb-3">
                <label htmlFor="companyName">Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="companyName"
                  onChange={changeHandler}
                  id="companyName"
                  value={userProfile.companyName}
                />
              </div>
              <p className="mb-0">Address</p>
              <div className="border p-2 mb-3">
                <div className="form-group row mb-0">
                  <label htmlFor="address1" className="col-sm-3 col-form-label">
                    Line 1
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="address1"
                      onChange={changeHandler}
                      id="address1"
                      value={userProfile.address1}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label htmlFor="address2" className="col-sm-3 col-form-label">
                    Line 2
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="address2"
                      onChange={changeHandler}
                      id="address2"
                      value={userProfile.address2}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label htmlFor="address3" className="col-sm-3 col-form-label">
                    Line 3
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="address3"
                      onChange={changeHandler}
                      id="address3"
                      value={userProfile.address3}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label htmlFor="city" className="col-sm-3 col-form-label">
                    City
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      onChange={changeHandler}
                      id="city"
                      value={userProfile.city}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label htmlFor="county" className="col-sm-3 col-form-label">
                    County
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="county"
                      onChange={changeHandler}
                      id="county"
                      value={userProfile.county}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label htmlFor="postcode" className="col-sm-3 col-form-label">
                    Postcode
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      name="postcode"
                      onChange={changeHandler}
                      id="postcode"
                      value={userProfile.postcode}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="companyGasSafeNumber">Gas Safe Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="companyGasSafeNumber"
                  id="companyGasSafeNumber"
                  value={userProfile.companyGasSafeNumber}
                  onChange={changeHandler}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="radioCompanyType" className="me-4">
                  Type
                </label>
                <div className="form-check form-check-inline" id="radioCompanyType">
                  <label className="form-check-label" htmlFor="radioCompanyTypeSole">
                    Sole Trader
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="companyType"
                    id="radioCompanyTypeSole"
                    value="soleTrader"
                    onChange={changeHandler}
                    checked={userProfile.companyType == "soleTrader" ? "checked" : ""}
                  />
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label" htmlFor="radioCompanyTypeLimited">
                    Ltd. Company
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="companyType"
                    id="radioCompanyTypeLimited"
                    value="limitedCompany"
                    onChange={changeHandler}
                    checked={userProfile.companyType == "limitedCompany" ? "checked" : ""}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="companyNumber">Limited Company Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="companyNumber"
                  id="companyNumber"
                  value={userProfile.companyNumber}
                  onChange={changeHandler}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="radioVatRegistered" className="me-4">
                  VAT Registered
                </label>
                <div className="form-check form-check-inline" id="radioVatRegisteredYes">
                  <label className="form-check-label" htmlFor="radioVatRegisteredYes">
                    Yes
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="vatRegistered"
                    id="radioVatRegisteredYes"
                    value="true"
                    onChange={changeHandler}
                    checked={userProfile.vatRegistered == true || userProfile.vatRegistered == "true" ? "checked" : ""}
                  />
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label" htmlFor="radioVatRegisteredNo">
                    No
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="vatRegistered"
                    id="radioVatRegisteredNo"
                    value="false"
                    onChange={changeHandler}
                    checked={
                      userProfile.vatRegistered == false || userProfile.vatRegistered == "false" ? "checked" : ""
                    }
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="vatNumber">VAT Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="vatNumber"
                  id="vatNumber"
                  value={userProfile.vatNumber}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 text-center">
            <button type="submit" className="btn btn-success me-3" onClick={saveHandler}>
              Save
            </button>
            <button type="cancel" className="btn btn-danger" onClick={cancelHandler}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

EditPersonalDetails.propTypes = {
  userProfile: PropType.object.isRequired,
  saveHandler: PropType.func.isRequired,
  changeHandler: PropType.func.isRequired,
  uploadProfileImageHandler: PropType.func.isRequired,
  formError: PropType.bool.isRequired,
  formErrorMessage: PropType.array.isRequired,
  cancelHandler: PropType.func.isRequired,
};

export default EditPersonalDetails;
