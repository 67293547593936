import React, { useState } from "react";
import PropTypes from "prop-types";
import { Calendar, DateObject } from "react-multi-date-picker";
import { addAvailability, removeAvailability } from "../../api/engineerApi";

// eslint-disable-next-line no-unused-vars
const Availability = ({ userProfile, auth }) => {
  const today = new Date();
  const dateFormatter = ({ date }) => {
    let props = {};

    let isWeekend = [0, 6].includes(date.weekDay.index);

    if (isWeekend) props.className = "highlight highlight-red";

    return props;
  };

  const previousDates = () => {
    const availableDates = [];
    userProfile.availability.forEach((availableDate) => {
      const markDate = new DateObject(availableDate.dateAvailable);
      availableDates.push(markDate);
    });
    return availableDates;
  };

  const dateChangeHandler = async (dateFocused, dateClicked) => {
    if (dateFocused !== undefined && dateFocused.toDate().toDateString() == dateClicked.toDate().toDateString()) {
      // Date added
      addAvailability(auth, dateFocused.toDate().toDateString());
    } else {
      // Date removed
      removeAvailability(auth, dateClicked.toDate().toDateString());
    }
  };

  const [availableDates, setAvailableDates] = useState(previousDates());
  const [calenderProps] = useState({
    multiple: true,
    value: availableDates,
    onChange: setAvailableDates,
    numberOfMonths: 3,
    minDate: today,
    weekStartDayIndex: 1,
    mapDays: dateFormatter,
    onFocusedDateChange: dateChangeHandler,
  });

  return (
    <div className="row">
      <div className="col-xs-6 col-md-12">
        <p className="lead fw-bold">Availability</p>
        <div id="calendarArea">
          <Calendar {...calenderProps} />
        </div>
      </div>
    </div>
  );
};

Availability.propTypes = {
  userProfile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default Availability;
