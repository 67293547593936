const responseTypes = () => {
  return {
    BOILER_COMBI: "Combi",
    BOILER_SYSTEM: "System",
    BOILER_REGULAR: "Regular",
    BOILER_BACK: "Back",
    FUEL_MAINS_GAS: "Mains Gas",
    FUEL_LPG: "LPG",
    RESPONSE_YES: "Yes",
    RESPONSE_NO: "No",
    LOCATION_GARAGE: "Garage",
    LOCATION_UTILITY_ROOM: "Utility Room",
    LOCATION_LIVING_ROOM: "Living Room",
    LOCATION_KITCHEN: "Kitchen",
    LOCATION_AIRING_CUPBOARD: "Airing Cupboard",
    LOCATION_SAME_ROOM: "Same Room",
    LOCATION_LOFT: "Loft",
    LOCATION_OTHER: "Other",
    HOUSE_DETACHED: "Detached",
    HOUSE_SEMI_DETACHED: "Semi Detached",
    HOUSE_TERRACED: "Terraced",
    HOUSE_BUNGALOW: "Bungalow",
    HOUSE_APARTMENT: "Apartment",
    FLOOR_NUMBER_GROUND: "Ground",
    FLOOR_NUMBER_FIRST: "First",
    FLOOR_NUMBER_SECOND_PLUS: "Second+",
    NUMBER_ZERO: "0",
    NUMBER_ONE: "1",
    NUMBER_TWO: "2",
    NUMBER_THREE: "3",
    NUMBER_THREE_PLUS: "3+",
    NUMBER_FOUR: "4",
    NUMBER_FIVE_PLUS: "5+",
    EXIT_EXTERNAL_WALL: "External Wall",
    EXIT_ROOF: "Roof",
    MORE_THAN_30CM: "More than 30cm",
    LESS_THAN_30CM: "Less than 30cm",
    ROOF_FLAT: "Flat",
    ROOF_PITCHED: "Pitched",
    HIGHEST_2_THIRD: "Highest 2 Thirds",
    LOWEST_THIRD: "Lowest Third",
    STATUS_NEW: "New",
    STATUS_ASSIGNED: "Assigned",
    STATUS_INSTALLED: "Installed",
    STATUS_QA_PENDING: "QA_Pending",
    STATUS_CHECKED: "Checked",
    STATUS_COMPLETE: "Complete",
    STATUS_CANCELLED: "Cancelled",
    ELECTRIC_BONDED_YES10MM: "Yes10mm",
    ELECTRIC_BONDED_YES6MM: "Yes6mm",
    ELECTRIC_BONDED_NO: "No",
    ELECTRIC_BONDED_NA: "NotApplicable",
    ELECTRIC_DISCONNECT_SPUR: "Spur",
    ELECTRIC_DISCONNECT_PLUG: "Plug",
    ELECTRIC_HEATING_CONTROLS_YES: "Yes",
    ELECTRIC_HEATING_CONTROLS_NO: "No",
    ELECTRIC_HEATING_CONTROLS_NA: "NotApplicable",
    ELECTRIC_HEATING_CONROLS_DEMO_YES: "Yes",
    ELECTRIC_HEATING_CONROLS_DEMO_NO: "No",
    ELECTRIC_HEATING_CONROLS_DEMO_NA: "NotApplicable",
  };
};

export default responseTypes;
