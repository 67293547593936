import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import ViewGasSafeCard from "./ViewGasSafeCard";
import UpdateGasSafeCard from "./UpdateGasSafeCard";
import { updateGasSafeCard, updateStandardDocument, getExpiryFieldName } from "../../api/engineerApi";
import * as engineerApi from "../../api/engineerApi";
import ViewStandardDocument from "./ViewStandardDocument";
import UpdateStandardDocument from "./UpdateStandardDocument";

const GAS_SAFE_CARD = "gasSafeCard";
const CCN1_DOCUMENT = "CCN1Document";
const FLUE_GAS_ANALYSER = "flueGasAnalyserCertificate";
const INSURANCE_DOCUMENT = "insuranceDocument";
const DBS_CERTIFICATE = "dbsCertificate";

// eslint-disable-next-line no-unused-vars
function Documents({ profile, auth, loadProfile }) {
  moment.locale("en");

  const [updatingGasCard, setUpdatingGasCard] = useState(false);
  const [updatingCCN1, setUpdatingCCN1] = useState(false);
  const [updatingFlueGasAnalyserCert, setUpdatingFlueGasAnalyserCert] = useState(false);
  const [updatingInsurance, setUpdatingInsurance] = useState(false);
  const [updatingDbs, setUpdatingDbs] = useState(false);

  const [gasSafeCardFrontImage, setGasSafeCardFrontImage] = useState(null);
  const [gasSafeCardRearImage, setGasSafeCardRearImage] = useState(null);
  const [gasSafeCardExpiry, setGasSafeCardExpiry] = useState(null);
  const [CCN1Document, setCCN1Document] = useState(null);
  const [CCN1Expiry, setCCN1Expiry] = useState(null);
  const [flueGasAnalyserCert, setFlueGasAnalyserCert] = useState(null);
  const [flueGasAnalyserExpiry, setFlueGasAnalyserExpiry] = useState(null);
  const [insuranceDocument, setInsuranceDocument] = useState(null);
  const [insuranceExpiry, setInsuranceExpiry] = useState(null);
  const [dbsCert, setDbsCert] = useState(null);
  const [dbsIssued, setDbsIssued] = useState(null);

  const [formError, setFormError] = useState(false);

  const uploadGasSafeCardFrontToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setGasSafeCardFrontImage(event.target.files[0]);
    }
  };

  const uploadGasSafeCardRearToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setGasSafeCardRearImage(event.target.files[0]);
    }
  };

  const handleGasSafeCardExpiry = (event) => {
    event.preventDefault();
    setGasSafeCardExpiry(event.target.value);
  };

  const uploadCCN1ToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setCCN1Document(event.target.files[0]);
    }
  };

  const handleCCN1Expiry = (event) => {
    event.preventDefault();
    setCCN1Expiry(event.target.value);
  };

  const uploadFlueGasAnalyserCertToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setFlueGasAnalyserCert(event.target.files[0]);
    }
  };

  const handleFlueGasAnalyserCertExpiry = (event) => {
    event.preventDefault();
    setFlueGasAnalyserExpiry(event.target.value);
  };

  const uploadInsuranceDocToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setInsuranceDocument(event.target.files[0]);
    }
  };

  const handleInsuranceExpiry = (event) => {
    event.preventDefault();
    setInsuranceExpiry(event.target.value);
  };

  const uploadDbsCertToClient = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setDbsCert(event.target.files[0]);
    }
  };

  const handleDbsIssued = (event) => {
    event.preventDefault();
    setDbsIssued(event.target.value);
  };

  const updateClickHandler = (event) => {
    event.preventDefault();

    switch (event.target.value) {
      case GAS_SAFE_CARD:
        setUpdatingGasCard(true);
        break;
      case CCN1_DOCUMENT:
        setUpdatingCCN1(true);
        break;
      case FLUE_GAS_ANALYSER:
        setUpdatingFlueGasAnalyserCert(true);
        break;
      case INSURANCE_DOCUMENT:
        setUpdatingInsurance(true);
        break;
      case DBS_CERTIFICATE:
        setUpdatingDbs(true);
        break;
    }
  };

  const saveClickHandler = async (event) => {
    event.preventDefault();

    switch (event.target.value) {
      case GAS_SAFE_CARD:
        if (gasSafeCardExpiry === null || gasSafeCardFrontImage === null || gasSafeCardRearImage === null) {
          setFormError(true);
        } else {
          await updateGasSafeCard(auth, gasSafeCardFrontImage, gasSafeCardRearImage, gasSafeCardExpiry);
          await loadProfile(auth);
          setUpdatingGasCard(false);
        }

        break;

      case CCN1_DOCUMENT:
        if (CCN1Document === null || CCN1Expiry === null) {
          setFormError(true);
        } else {
          await updateStandardDocument(auth, CCN1Document, CCN1Expiry, engineerApi.CCN1);
          await loadProfile(auth);
          setUpdatingCCN1(false);
        }
        break;

      case FLUE_GAS_ANALYSER:
        if (flueGasAnalyserCert === null || flueGasAnalyserExpiry === null) {
          setFormError(true);
        } else {
          await updateStandardDocument(
            auth,
            flueGasAnalyserCert,
            flueGasAnalyserExpiry,
            engineerApi.FLUE_GAS_ANALYSER_CERTIFICATE
          );
          await loadProfile(auth);
          setUpdatingFlueGasAnalyserCert(false);
        }
        break;

      case INSURANCE_DOCUMENT:
        if (insuranceDocument === null || insuranceExpiry === null) {
          setFormError(true);
        } else {
          await updateStandardDocument(auth, insuranceDocument, insuranceExpiry, engineerApi.INSURANCE);
          await loadProfile(auth);
          setUpdatingInsurance(false);
        }
        break;

      case DBS_CERTIFICATE:
        if (dbsCert === null || dbsIssued === null) {
          setFormError(true);
        } else {
          await updateStandardDocument(auth, dbsCert, dbsIssued, engineerApi.DBS_CERTIFICATE);
          await loadProfile(auth);
          setUpdatingDbs(false);
        }
        break;
    }
  };

  const cancelClickHandler = (event) => {
    event.preventDefault();

    switch (event.target.value) {
      case GAS_SAFE_CARD:
        setUpdatingGasCard(false);
        setGasSafeCardExpiry(null);
        setGasSafeCardFrontImage(null);
        setGasSafeCardRearImage(null);
        setFormError(false);
        break;

      case CCN1_DOCUMENT:
        setUpdatingCCN1(false);
        setCCN1Document(null);
        setCCN1Expiry(null);
        setFormError(false);
        break;

      case FLUE_GAS_ANALYSER:
        setUpdatingFlueGasAnalyserCert(false);
        setFlueGasAnalyserCert(null);
        setFlueGasAnalyserExpiry(null);
        setFormError(false);
        break;

      case INSURANCE_DOCUMENT:
        setUpdatingInsurance(false);
        setInsuranceDocument(null);
        setInsuranceExpiry(null);
        setFormError(false);
        break;

      case DBS_CERTIFICATE:
        setUpdatingDbs(false);
        setDbsCert(null);
        setDbsIssued(null);
        setFormError(false);
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <p className="lead fw-bold">Documents and Certifications</p>
      </div>

      <div className="col-xs-12 col-md-12">
        <div id="gasSafeCard" className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h5 className="text-muted">Gas Safe Card</h5>
              </div>
              <div className="col-xs-12 col-md-4">
                <p className="text-muted mb-0">
                  Expiry Date:{" "}
                  <span className="fw-bold">
                    {moment(profile[getExpiryFieldName(engineerApi.GAS_SAFE_CARD_FRONT)]).format("MMM Do YYYY")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {updatingGasCard === false ? (
              <ViewGasSafeCard userProfile={profile} />
            ) : (
              <UpdateGasSafeCard
                uploadCardFrontToClientFunc={uploadGasSafeCardFrontToClient}
                uploadCardRearToClientFunc={uploadGasSafeCardRearToClient}
                inputCardExpiryFunc={handleGasSafeCardExpiry}
                formError={formError}
              />
            )}
          </div>
          <div className="card-footer text-center">
            {updatingGasCard === false ? (
              <button
                className="btn btn-lg btn-success align-middle px-3"
                value={GAS_SAFE_CARD}
                onClick={updateClickHandler}
              >
                Update
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-lg btn-success align-middle px-3 me-3"
                  value={GAS_SAFE_CARD}
                  onClick={saveClickHandler}
                >
                  Save
                </button>

                <button
                  className="btn btn-lg btn-danger align-middle px-3"
                  value={GAS_SAFE_CARD}
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>

        <div id="ccn1" className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h5 className="text-muted">CCN1 Document</h5>
              </div>
              <div className="col-xs-12 col-md-4">
                <p className="text-muted mb-0">
                  Expiry Date:{" "}
                  <span className="fw-bold">
                    {moment(profile[getExpiryFieldName(engineerApi.CCN1)]).format("MMM Do YYYY")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {updatingCCN1 === false ? (
              <ViewStandardDocument userProfile={profile} documentType={engineerApi.CCN1} />
            ) : (
              <UpdateStandardDocument
                documentName={engineerApi.CCN1}
                uploadDocToClientFunc={uploadCCN1ToClient}
                handleDocExpiryFunc={handleCCN1Expiry}
                formError={formError}
              />
            )}
          </div>
          <div className="card-footer text-center">
            {updatingCCN1 === false ? (
              <button
                className="btn btn-lg btn-success align-middle px-3"
                value={CCN1_DOCUMENT}
                onClick={updateClickHandler}
              >
                Update
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-lg btn-success align-middle px-3 me-3"
                  value={CCN1_DOCUMENT}
                  onClick={saveClickHandler}
                >
                  Save
                </button>

                <button
                  className="btn btn-lg btn-danger align-middle px-3"
                  value={CCN1_DOCUMENT}
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>

        <div id="flueGasAnalyser" className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h5 className="text-muted">Flue Gas Analyser Certificate</h5>
              </div>
              <div className="col-xs-12 col-md-4">
                <p className="text-muted mb-0">
                  Expiry Date:{" "}
                  <span className="fw-bold">
                    {moment(profile[getExpiryFieldName(engineerApi.FLUE_GAS_ANALYSER_CERTIFICATE)]).format(
                      "MMM Do YYYY"
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {updatingFlueGasAnalyserCert === false ? (
              <ViewStandardDocument userProfile={profile} documentType={engineerApi.FLUE_GAS_ANALYSER_CERTIFICATE} />
            ) : (
              <UpdateStandardDocument
                documentName={engineerApi.FLUE_GAS_ANALYSER_CERTIFICATE}
                uploadDocToClientFunc={uploadFlueGasAnalyserCertToClient}
                handleDocExpiryFunc={handleFlueGasAnalyserCertExpiry}
                formError={formError}
              />
            )}
          </div>
          <div className="card-footer text-center">
            {updatingFlueGasAnalyserCert === false ? (
              <button
                className="btn btn-lg btn-success align-middle px-3"
                value={FLUE_GAS_ANALYSER}
                onClick={updateClickHandler}
              >
                Update
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-lg btn-success align-middle px-3 me-3"
                  value={FLUE_GAS_ANALYSER}
                  onClick={saveClickHandler}
                >
                  Save
                </button>

                <button
                  className="btn btn-lg btn-danger align-middle px-3"
                  value={FLUE_GAS_ANALYSER}
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>

        <div id="insuranceDoc" className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h5 className="text-muted">Public Liability Insurance</h5>
              </div>
              <div className="col-xs-12 col-md-4">
                <p className="text-muted mb-0">
                  Expiry Date:{" "}
                  <span className="fw-bold">
                    {moment(profile[getExpiryFieldName(engineerApi.INSURANCE)]).format("MMM Do YYYY")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {updatingInsurance === false ? (
              <ViewStandardDocument userProfile={profile} documentType={engineerApi.INSURANCE} />
            ) : (
              <UpdateStandardDocument
                documentName={engineerApi.INSURANCE}
                uploadDocToClientFunc={uploadInsuranceDocToClient}
                handleDocExpiryFunc={handleInsuranceExpiry}
                formError={formError}
              />
            )}
          </div>
          <div className="card-footer text-center">
            {updatingInsurance === false ? (
              <button
                className="btn btn-lg btn-success align-middle px-3"
                value={INSURANCE_DOCUMENT}
                onClick={updateClickHandler}
              >
                Update
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-lg btn-success align-middle px-3 me-3"
                  value={INSURANCE_DOCUMENT}
                  onClick={saveClickHandler}
                >
                  Save
                </button>

                <button
                  className="btn btn-lg btn-danger align-middle px-3"
                  value={INSURANCE_DOCUMENT}
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>

        <div id="dbsCertificate" className="card mb-5">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h5 className="text-muted">DBS Certificate</h5>
              </div>
              <div className="col-xs-12 col-md-4">
                <p className="text-muted mb-0">
                  Issued Date:{" "}
                  <span className="fw-bold">
                    {moment(profile[getExpiryFieldName(engineerApi.DBS_CERTIFICATE)]).format("MMM Do YYYY")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            {updatingDbs === false ? (
              <ViewStandardDocument userProfile={profile} documentType={engineerApi.DBS_CERTIFICATE} />
            ) : (
              <UpdateStandardDocument
                documentName={engineerApi.DBS_CERTIFICATE}
                uploadDocToClientFunc={uploadDbsCertToClient}
                handleDocExpiryFunc={handleDbsIssued}
                formError={formError}
                issuedDate={true}
              />
            )}
          </div>
          <div className="card-footer text-center">
            {updatingDbs === false ? (
              <button
                className="btn btn-lg btn-success align-middle px-3"
                value={DBS_CERTIFICATE}
                onClick={updateClickHandler}
              >
                Update
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-lg btn-success align-middle px-3 me-3"
                  value={DBS_CERTIFICATE}
                  onClick={saveClickHandler}
                >
                  Save
                </button>

                <button
                  className="btn btn-lg btn-danger align-middle px-3"
                  value={DBS_CERTIFICATE}
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Documents.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
};

export default Documents;
