import React from "react";
import PropTypes from "prop-types";
import JobDetails from "./JobDetail";
import responseTypes from "../../utils/responseTypes";

const MyJobs = ({ myJobs, completeJobHandler }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <p className="lead fw-bold">My Jobs</p>
        {myJobs.length === 0 ? (
          <p className="fw-bold">You have no jobs to display</p>
        ) : (
          myJobs.map((job) => {
            return (
              <div key={job.orderNumber} className="card mb-3">
                <div className="card-header">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <p className="align-middle my-2">
                        <span className="lead">Order Number: {job.orderNumber}</span>
                      </p>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <p className="align-middle my-2 text-end">
                        <span className="lead fw-bold">
                          Order Value:{" "}
                          {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
                            job.engineerTotal
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  {job.orderStatus === responseTypes().STATUS_INSTALLED ? (
                    <div className="row">
                      <div className="col-xs-12">
                        <p className="fw-bold text-success">Installation Complete - Awaiting Documentation</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <JobDetails job={job} options={{ showPersonalInfo: true }} />
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-success me-2 mb-2"
                        value={job.orderNumber}
                        onClick={completeJobHandler}
                      >
                        Complete Job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

MyJobs.propTypes = {
  myJobs: PropTypes.array.isRequired,
  completeJobHandler: PropTypes.func.isRequired,
};

export default MyJobs;
