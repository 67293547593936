import { handleResponse, handleError } from "./apiUtils";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = apiUrl.concat("/job");

export async function getNewOrders(auth) {
  try {
    const response = await fetch(baseUrl.concat(`/new`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function getOrder(auth, orderNumber) {
  try {
    const response = await fetch(baseUrl.concat("/job-by-ordernumber"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
      body: JSON.stringify({ orderNumber }),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function getOrderWithCompletionDetails(auth, orderNumber) {
  try {
    const response = await fetch(baseUrl.concat("/job-with-completion-details"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
      body: JSON.stringify({ orderNumber }),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function claimOrder(auth, job, engineer) {
  try {
    const response = await fetch(baseUrl.concat("/claim"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
      body: JSON.stringify({ job, engineer }),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function getMyOrders(auth) {
  try {
    const response = await fetch(baseUrl.concat(`/myjob`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function getMyCompletedOrders(auth) {
  try {
    const response = await fetch(baseUrl.concat(`/mycompletedjobs`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function startCompletion(auth, job) {
  return fetch(baseUrl.concat("/complete/start"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitSatisfaction(auth, job, signatureBase64) {
  return fetch(baseUrl.concat("/complete/satisfaction"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job, signatureBase64 }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitSerialNumbers(auth, job, serialNumbers) {
  return fetch(baseUrl.concat("/complete/serial-numbers"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job, serialNumbers }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitBenchmark(auth, job, benchmarkImage) {
  const sendBody = new FormData();
  sendBody.append("completedBenchmarkImage", benchmarkImage);
  sendBody.append("orderNumber", job.orderNumber);

  return fetch(baseUrl + "/complete/benchmark", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: sendBody,
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitGasSignOff(auth, job, gasCP1Image, gasSignOffData) {
  const sendBody = new FormData();
  sendBody.append("gasCP1Image", gasCP1Image);
  sendBody.append("orderNumber", job.orderNumber);
  sendBody.append("gasSignOffData", JSON.stringify(gasSignOffData));

  return fetch(baseUrl + "/complete/gas-sign-off", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: sendBody,
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitElectricalSignOff(auth, job, electricalSignOffData) {
  return fetch(baseUrl.concat("/complete/electric-sign-off"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job, electricalSignOffData }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function uploadInstallationImage(auth, job, installationImage, imageType) {
  const sendBody = new FormData();
  sendBody.append("installationImage", installationImage);
  sendBody.append("imageType", imageType);
  sendBody.append("orderNumber", job.orderNumber);

  return fetch(baseUrl + "/complete/add-installation-image", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: sendBody,
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function removeInstallationImage(auth, job, removeImageId) {
  return fetch(baseUrl.concat("/complete/remove-installation-image"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job, removeImageId }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitAllInstallationImages(auth, job) {
  return fetch(baseUrl.concat("/complete/submit-installation-images"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}

export async function submitJobForQA(auth, job) {
  return fetch(baseUrl.concat("/complete/submit-job-for-qa"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    body: JSON.stringify({ job }),
  })
    .then((response) => {
      handleResponse(response);
    })
    .catch(handleError);
}
