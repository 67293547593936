import React from "react";
import PropTypes from "prop-types";

function UpdateStandardDocument({ documentName, uploadDocToClientFunc, handleDocExpiryFunc, formError, hideExpiry }) {
  return (
    <div className="row">
      <div className="form-group mb-3">
        <label htmlFor="uploadGasSafeCardRear">Upload an image of {documentName}</label>
        <input
          type="file"
          className="form-control"
          id="uploadDocument"
          name="uploadDocument"
          placeholder={`upload your ${documentName}`}
          onChange={uploadDocToClientFunc}
          accept="image/*, .pdf"
          required
        />
      </div>
      {hideExpiry ? (
        ""
      ) : (
        <div className="form-group mb-3">
          <label htmlFor="inputGasSafeExpiryDate">Enter the date your {documentName} expires</label>
          <input
            type="date"
            className="form-control"
            id="inputDocumentExpiryDate"
            name="documentExpiryDate"
            placeholder="enter expiry date"
            onChange={handleDocExpiryFunc}
            required
          />
        </div>
      )}
      {formError ? <p className="fw-bold text-danger">*Please fill in all details</p> : <></>}
    </div>
  );
}

UpdateStandardDocument.propTypes = {
  documentName: PropTypes.string.isRequired,
  uploadDocToClientFunc: PropTypes.func.isRequired,
  handleDocExpiryFunc: PropTypes.func,
  formError: PropTypes.bool.isRequired,
  hideExpiry: PropTypes.bool,
};

export default UpdateStandardDocument;
