import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="jumbotron">
            <h1>BOXHEAT Engineers Hub</h1>
            <p>Welcome to the BOXHEAT Engineers Hub</p>
            <Link to={"jobs"} className="btn btn-primary btn-lg me-3">
              <FontAwesomeIcon icon={faShoppingBag} className="me-2" />
              Jobs
            </Link>
            <Link to={"engineer"} className="btn btn-primary btn-lg">
              <FontAwesomeIcon icon={faInfoCircle} className="fa me-2" />
              My Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
