import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { updateCoveredAreas } from "../../api/engineerApi";
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
const AreaCovered = ({ userProfile, regions, areas, profileAuth, loadProfile }) => {
  const [myAreasCovered, setMyAreasCovered] = useState(userProfile.coverage_areas.data);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const myNewAreas = userProfile.coverage_areas.data;
    setMyAreasCovered(myNewAreas);
  }, [userProfile.coverage_areas.data]);

  const checkIfCovered = (areaCode) => {
    let returnValue = false;

    myAreasCovered.forEach((area) => {
      if (area.attributes.areaCode === areaCode) {
        returnValue = true;
      }
    });

    return returnValue;
  };

  const onChangeLocal = (event) => {
    setFormChanged(true);

    let myNewAreas = [];

    if (event.target.checked === true) {
      // Create a copy of myAreas array and include the newly selected area
      myNewAreas = [...myAreasCovered];
      myNewAreas.push(
        areas.find((area) => {
          return area.id === Number(event.target.id);
        })
      );
    } else {
      // Create a copy of myAreas array and remove the unselected area
      myNewAreas = myAreasCovered.filter((area) => {
        return area.id !== Number(event.target.id);
      });
    }

    setMyAreasCovered(myNewAreas);
  };

  const cancelChanges = () => {
    setMyAreasCovered(userProfile.coverage_areas.data);
    setFormChanged(false);
  };

  const saveChanges = async () => {
    setFormChanged(false);

    toast.success("Saving", {
      autoClose: 2000,
      hideProgressBar: true,
      position: "top-right",
      bodyClassName: "toast-body",
      progressClassName: "toast-progress",
      className: "toast-message",
      closeButton: false,
      icon: false,
    });

    await updateCoveredAreas(profileAuth, myAreasCovered);
    await loadProfile(profileAuth);
  };

  return (
    <div className="row">
      <p className="lead fw-bold">Areas Covered</p>
      <div className="col-md-8">
        <p className="p-3 mb-2 bg-light">Amend Covered Areas</p>
        <div className="accordion" id="areasAccordion">
          {regions.map((region) => {
            const regionName = region;
            let regionNameNoSpace = regionName.replace(" ", "");
            regionNameNoSpace = Math.floor(Math.random() * 1000); // Resetting to this because names is breaking the accordion
            const regionAreas = areas.filter((area) => area.attributes.region === region);

            return (
              <div key={region} className="border-end pe-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id={"heading" + regionNameNoSpace}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + regionNameNoSpace}
                      aria-expanded="false"
                      aria-controls={"collapse" + regionNameNoSpace}
                    >
                      {regionName}
                    </button>
                  </h2>

                  <div
                    id={"collapse" + regionNameNoSpace}
                    className="accordion-collapse collapse"
                    aria-labelledby={"heading" + regionNameNoSpace}
                    data-bs-parent="#areasAccordion"
                  >
                    <div className="accordion-body">
                      {regionAreas.map((area) => {
                        const areaCode = area.attributes.areaCode;
                        const areaName = area.attributes.areaName;
                        const areaId = area.id;

                        return (
                          <div key={area.id} className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={areaId}
                              role="switch"
                              checked={checkIfCovered(areaCode)}
                              onChange={onChangeLocal}
                            />
                            <label className="form-check-label" labelfor={areaCode}>
                              {areaCode + " - " + areaName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-md-4 shadow">
        <p className="py-3 mb-2">Your current areas</p>
        {formChanged ? <p className="text-danger">You have unsaved changes</p> : ""}
        {myAreasCovered.map((area) => {
          return (
            <div key={area.id}>
              <p>{`${area.attributes.areaCode} - ${area.attributes.areaName}`}</p>
            </div>
          );
        })}
      </div>
      {formChanged ? (
        <div className="border-1 border-dark border bg-light m-3">
          <div className="row">
            <div className="col-md-12 mt-3 text-center">
              <p className="lead">Do you want to save your changes?</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-0 mb-3 text-center">
              <button type="submit" className="btn btn-success me-3" onClick={saveChanges}>
                Save
              </button>
              <button type="cancel" className="btn btn-danger" onClick={cancelChanges}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

AreaCovered.propTypes = {
  userProfile: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  profileAuth: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
};

export default AreaCovered;
