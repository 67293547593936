import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
const AreaCovered = ({ userProfile, regions, areas, onChange }) => {
  const [myAreasCovered, setMyAreasCovered] = useState(userProfile.coverage_areas.data);

  useEffect(() => {
    const myNewAreas = userProfile.coverage_areas.data;
    setMyAreasCovered(myNewAreas);
  }, [userProfile.coverage_areas.data]);

  const checkIfCovered = (areaCode) => {
    let returnValue = false;

    myAreasCovered.forEach((area) => {
      if (area.attributes.areaCode === areaCode) {
        returnValue = true;
      }
    });

    return returnValue;
  };

  return (
    <div className="row">
      <p className="lead fw-bold">Areas Covered</p>
      <div className="col-md-8">
        <p className="p-3 mb-2 bg-light">Amend Covered Areas</p>
        <div className="accordion" id="areasAccordion">
          {regions.map((region) => {
            const regionName = region;
            let regionNameNoSpace = regionName.replace(" ", "");
            regionNameNoSpace = Math.floor(Math.random() * 1000); // Resetting to this because names is breaking the accordion
            const regionAreas = areas.filter((area) => area.attributes.region === region);

            return (
              <div key={region} className="border-end pe-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id={"heading" + regionNameNoSpace}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + regionNameNoSpace}
                      aria-expanded="false"
                      aria-controls={"collapse" + regionNameNoSpace}
                    >
                      {regionName}
                    </button>
                  </h2>

                  <div
                    id={"collapse" + regionNameNoSpace}
                    className="accordion-collapse collapse"
                    aria-labelledby={"heading" + regionNameNoSpace}
                    data-bs-parent="#areasAccordion"
                  >
                    <div className="accordion-body">
                      {regionAreas.map((area) => {
                        const areaCode = area.attributes.areaCode;
                        const areaName = area.attributes.areaName;
                        const areaId = area.id;

                        return (
                          <div key={area.id} className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={areaId}
                              role="switch"
                              checked={checkIfCovered(areaCode)}
                              onChange={onChange}
                            />
                            <label className="form-check-label" labelfor={areaCode}>
                              {areaCode + " - " + areaName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-md-4 shadow">
        <p className="py-3 mb-2">Your current areas</p>
        {myAreasCovered.map((area) => {
          return (
            <div key={area.id}>
              <p>{`${area.attributes.areaCode} - ${area.attributes.areaName}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

AreaCovered.propTypes = {
  userProfile: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AreaCovered;
